import dayjs from "dayjs";
import "./gallery.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import UserServices from "../services/UserServices";
import { filesize } from "filesize";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { yellow } from "@mui/material/colors";
function ProductModal(prop) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const item = prop.item;

  const docImage =
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktZmlsZS1lYXJtYXJrLXRleHQiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZD0iTTUuNSA3YS41LjUgMCAwIDAgMCAxaDVhLjUuNSAwIDAgMCAwLTFoLTV6TTUgOS41YS41LjUgMCAwIDEgLjUtLjVoNWEuNS41IDAgMCAxIDAgMWgtNWEuNS41IDAgMCAxLS41LS41em0wIDJhLjUuNSAwIDAgMSAuNS0uNWgyYS41LjUgMCAwIDEgMCAxaC0yYS41LjUgMCAwIDEtLjUtLjV6Ii8+CiAgPHBhdGggZD0iTTkuNSAwSDRhMiAyIDAgMCAwLTIgMnYxMmEyIDIgMCAwIDAgMiAyaDhhMiAyIDAgMCAwIDItMlY0LjVMOS41IDB6bTAgMXYyQTEuNSAxLjUgMCAwIDAgMTEgNC41aDJWMTRhMSAxIDAgMCAxLTEgMUg0YTEgMSAwIDAgMS0xLTFWMmExIDEgMCAwIDEgMS0xaDUuNXoiLz4KPC9zdmc+";

  const [productDocCategoryList, setProductDocCategoryList] = useState([]);

  const getProductDocCategory = () => {
    UserServices.getProductDocCategory()
      .then((response) => {
        setProductDocCategoryList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [catId, setCatId] = useState();
  function deleteDoc(productId, docId) {
    UserServices.techDocGalleryDelete(productId, docId).then((response) => {
      console.log(response);
      prop.load();
      docListapi(item.id, catId);
    });
    console.log("deleteDoc", productId, catId);
  }

  const [docList, setDocList] = useState([]);

  const docListapi = (productId, categoryId) => {
    UserServices.docList(productId, categoryId)
      .then((response) => {
        console.log("tab content", response.data);
        setDocList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleShow = () => {
    setShow(true);
    console.log(
      "prop modal",
      productDocCategoryList[selectedCategory]?.subDocCategories[0]?.id
    );
    docListapi(
      item.id,
      productDocCategoryList[selectedCategory]?.subDocCategories[0]?.id
    );
    setCatId(productDocCategoryList[selectedCategory]?.subDocCategories[0]?.id);
  };
  // useEffect(() => {
  //   getProductDocCategory();
  //   docListapi(item.id,productDocCategoryList[selectedCategory]?.subDocCategories[0]?.id)
  //   console.log("tab content", productDocCategoryList[selectedCategory])
  // }, []);
  useEffect(() => {
    getProductDocCategory();
  }, []);

  const [selectedCategory, setSelectedCategory] = useState(0);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    setTabValue(0);
    docListapi(
      item.id,
      productDocCategoryList[selectedCategory]?.subDocCategories[0]?.id
    );
  }, [selectedCategory]);
  const tabHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleDownload = async (name, fileType, documentUrl) => {
    //const fileType = documentUrl.split(".")[4]
    try {
      const response = await fetch(documentUrl);
      const blob = await response.blob();
      console.error("downloading file:", documentUrl.split("."));
      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${name}.${fileType}`; // Specify the filename for the downloaded file

      // Appending the link to the document body
      document.body.appendChild(link);

      // Triggering the download
      link.click();

      // Cleanup: remove the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <button type="button" className="btn btn-primary" onClick={handleShow}>
        {" "}
        <i className="bi bi-eye"></i>
      </button>
      <Modal show={show} onHide={handleClose} size={"xl"}>
        <Modal.Header closeButton>
          <Modal.Title className="model-text">{item.name}</Modal.Title>
          {/* <a href="!#" class="download">
            <img src="/assets/images/download.png" alt="" />
          </a> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-12">
                  <div
                    class="image-card-2"
                    data-bs-toggle="modal"
                    data-bs-target="#adduser"
                  >
                    <img
                      src={item.productImages[0].imageUrl}
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-7 p-0">
              <div className="price">
                <span className="doller">{item?.currency?.symbol}</span>
                <span className="price-value">{item.price}</span>
              </div>
              <div className="price-title">
                <b>Category:</b> {item.subCategory.name} | <b>Product Code:</b>{" "}
                {item.productCode}
              </div>
              <hr />

              <Form.Select
                aria-label="Default select example"
                onChange={(e) => handleCategoryChange(e)}
              >
                {productDocCategoryList.map((item, i) => (
                  <option value={i} selected={i == 0 ? true : false}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>

              <div className="tab-content">
                <div id="primary-pills-home">
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive mt-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="primary-pills-profile">
                  <nav>
                    <div
                      className="nav nav-tabs mb-3 p-2 custom-tab-2-content"
                      id="nav-tab"
                      role="tablist"
                    >
                      {/* {productDocCategoryList
                        .find((category) => category.id === selectedCategory)
                        .subDocCategories?.map((subCategory) => (
                          <button
                            className="nav-link active"
                            id="nav-home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-home"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                          >
                            Technical Data Sheet
                          </button>
                        ))} */}
                      <Tabs
                        value={tabValue}
                        onChange={tabHandleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        aria-label="scrollable auto tabs example"
                      >
                        {productDocCategoryList[
                          selectedCategory
                        ]?.subDocCategories?.map((subCategories, index) => (
                          <Tab
                            label={subCategories.name}
                            onClick={() => {
                              docListapi(item.id, subCategories.id);
                              setCatId(subCategories.id);
                            }}
                            sx={{
                              backgroundColor:
                                tabValue === index ? "#020073" : "inherit",
                              color:
                                tabValue === index
                                  ? "#fff !important"
                                  : "inherit",
                              borderRadius: "10px 10px 0px 0px",
                              borderBottom: "none",
                              textTransform: "capitalize",
                              fontSize: "12px",
                              fontWeight: "400",
                              fontFamily: "Inter",
                              padding: "5px",
                            }}
                          />
                        ))}
                      </Tabs>
                    </div>
                  </nav>
                  {console.log("tab content", docList)}
                  <div className="scrollbar-1">
                    <table className="table align-middle file-table">
                      <tbody className="">
                        {docList.length !== 0 ? (
                          docList.map((doc) => (
                            <tr>
                              <td width="78%">
                                <div className="d-flex align-items-center gap-3 cursor-pointer">
                                  <img
                                    //  src="/assets/images/pdf.png"
                                    src={docImage}
                                    className=""
                                    width="40px"
                                    height="auto"
                                    alt=""
                                  />

                                  <div>
                                    <div className="fileName">
                                      <p className="mb-0" color="#000">
                                        <b>{doc.name}</b>
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center  cursor-pointer">
                                      <div
                                        style={{
                                          width: 1,
                                          height: 28,
                                        }}
                                      />
                                      <div className="">
                                        <p className="mb-0" color="#000">
                                          {dayjs(doc.createdAt).format(
                                            "DD/MM/YY"
                                          )}{" "}
                                          | {doc.fileType} |{" "}
                                          {filesize(doc.fileSize, {
                                            standard: "jedec",
                                          })}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td width="22%">
                                <div className="d-flex align-items-center gap-3 cursor-pointer">
                                  <div style={{ width: 1, height: 28 }} />
                                  <div className="">
                                    <a
                                      // href={doc.documentUrl}
                                      // target="_blank"
                                      onClick={(e) =>
                                        handleDownload(
                                          doc.name,
                                          doc.fileType,
                                          doc.documentUrl
                                        )
                                      }
                                      className="download"
                                    >
                                      <img src="/assets/images/download.png" />
                                    </a>
                                    {prop.principle ? (
                                      <button
                                        type="button"
                                        class="btn btn-primary mx-1"
                                        onClick={() =>
                                          deleteDoc(item.id, doc.id)
                                        }
                                        style={{
                                          width: "46px",
                                          height: "46px",
                                          backgroundColor: "#020073",
                                          padding: 0,
                                        }}
                                      >
                                        <i
                                          class="bi bi-trash"
                                          style={{
                                            color: "#fff",
                                            fontSize: "25px",
                                          }}
                                        ></i>
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              height: "250px",
                            }}
                          >
                            <img
                              src="/assets/images/no-document.png"
                              style={{ width: "100px" }}
                            />
                            <p>
                              <b>No Documents</b>
                            </p>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="tab-content p-3 " id="nav-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      <div className="table-responsive mt-3"></div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div className="table-responsive mt-3">
                        <div className="scrollbar-1">
                          <table className="table align-middle file-table">
                            <tbody className="">
                              {item?.techinicalDocument !== null
                                ? item?.techinicalDocument?.sidi?.map((doc) => (
                                    <tr>
                                      <td width="79%">
                                        <div className="d-flex align-items-center gap-3 cursor-pointer">
                                          <img
                                            src={docImage}
                                            className=""
                                            width="40px"
                                            height="auto"
                                            alt=""
                                          />
                                          <div>
                                            <div className="fileName">
                                              <p className="mb-0" color="#000">
                                                <b>{doc.name}</b>
                                              </p>
                                            </div>
                                            <div className="d-flex align-items-center  cursor-pointer">
                                              <div
                                                style={{ width: 1, height: 28 }}
                                              />
                                              <div className="">
                                                <p
                                                  className="mb-0"
                                                  color="#000"
                                                >
                                                  {dayjs(doc.createdAt).format(
                                                    "DD/MM/YY"
                                                  )}{" "}
                                                  | {doc.fileType} |{" "}
                                                  {filesize(doc.fileSize, {
                                                    standard: "jedec",
                                                  })}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>

                                      <td width="21%">
                                        <div className="d-flex align-items-center gap-3 cursor-pointer">
                                          <div
                                            style={{ width: 1, height: 28 }}
                                          />
                                          <div className="">
                                            <a
                                              href={doc.documentUrl}
                                              className="download"
                                            >
                                              <img src="/assets/images/download.png" />
                                            </a>
                                            {prop.principle ? (
                                              <button
                                                type="button"
                                                class="btn btn-primary mx-1"
                                                onClick={() =>
                                                  deleteDoc(item.id, doc.id)
                                                }
                                                style={{
                                                  width: "40px",
                                                  height: "40px",
                                                  backgroundColor: "#020073",
                                                  padding: 0,
                                                }}
                                              >
                                                <i
                                                  class="bi bi-trash"
                                                  style={{
                                                    color: "#fff",
                                                    fontSize: "25px",
                                                  }}
                                                ></i>
                                              </button>
                                            ) : null}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-contact"
                      role="tabpanel"
                      aria-labelledby="nav-contact-tab"
                    >
                      <div className="table-responsive mt-3">
                        <div className="scrollbar-1">
                          <table className="table align-middle file-table">
                            <tbody className="">
                              {item?.techinicalDocument !== null
                                ? item?.techinicalDocument?.packagingInformation?.map(
                                    (doc) => (
                                      <tr>
                                        <td width="80%">
                                          <div className="d-flex align-items-center gap-3 cursor-pointer">
                                            <img
                                              src={docImage}
                                              className=""
                                              width="40px"
                                              height="auto"
                                              alt=""
                                            />
                                            <div>
                                              <div className="fileName">
                                                <p
                                                  className="mb-0"
                                                  color="#000"
                                                >
                                                  <b>{doc.name}</b>
                                                </p>
                                              </div>
                                              <div className="d-flex align-items-center  cursor-pointer">
                                                <div
                                                  style={{
                                                    width: 1,
                                                    height: 28,
                                                  }}
                                                />
                                                <div className="">
                                                  <p
                                                    className="mb-0"
                                                    color="#000"
                                                  >
                                                    {dayjs(
                                                      doc.createdAt
                                                    ).format("DD/MM/YY")}{" "}
                                                    | {doc.fileType} |{" "}
                                                    {filesize(doc.fileSize, {
                                                      standard: "jedec",
                                                    })}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>

                                        <td width="20%">
                                          <div className="d-flex align-items-center gap-3 cursor-pointer">
                                            <div
                                              style={{ width: 1, height: 28 }}
                                            />
                                            <div className="">
                                              <a
                                                href={doc.documentUrl}
                                                className="download"
                                              >
                                                <img src="/assets/images/download.png" />
                                              </a>
                                              {prop.principle ? (
                                                <button
                                                  type="button"
                                                  class="btn btn-primary mx-1"
                                                  onClick={() =>
                                                    deleteDoc(item.id, doc.id)
                                                  }
                                                  style={{
                                                    width: "46px",
                                                    height: "46px",
                                                    backgroundColor: "#020073",
                                                    padding: 0,
                                                  }}
                                                >
                                                  <i
                                                    class="bi bi-trash"
                                                    style={{
                                                      color: "#fff",
                                                      fontSize: "25px",
                                                    }}
                                                  ></i>
                                                </button>
                                              ) : null}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-contact-1"
                      role="tabpanel"
                      aria-labelledby="nav-contact-tab-ok"
                    >
                      <div className="table-responsive mt-3">
                        <div className="scrollbar-1">
                          <table className="table align-middle file-table">
                            <tbody className="">
                              {item?.techinicalDocument !== null
                                ? item?.techinicalDocument?.storageAndHandling?.map(
                                    (doc) => (
                                      <tr>
                                        <td width="80%">
                                          <div className="d-flex align-items-center gap-3 cursor-pointer">
                                            <img
                                              src={docImage}
                                              className=""
                                              width="40px"
                                              height="auto"
                                              alt=""
                                            />
                                            <div>
                                              <div className="fileName">
                                                <p
                                                  className="mb-0"
                                                  color="#000"
                                                >
                                                  <b>{doc.name}</b>
                                                </p>
                                              </div>
                                              <div className="d-flex align-items-center  cursor-pointer">
                                                <div
                                                  style={{
                                                    width: 1,
                                                    height: 28,
                                                  }}
                                                />
                                                <div className="">
                                                  <p
                                                    className="mb-0"
                                                    color="#000"
                                                  >
                                                    {dayjs(
                                                      doc.createdAt
                                                    ).format("DD/MM/YY")}{" "}
                                                    | {doc.fileType} |{" "}
                                                    {filesize(doc.fileSize, {
                                                      standard: "jedec",
                                                    })}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>

                                        <td width="20%">
                                          <div className="d-flex align-items-center gap-3 cursor-pointer">
                                            <div
                                              style={{ width: 1, height: 28 }}
                                            />
                                            <div className="">
                                              <a
                                                href={doc.documentUrl}
                                                className="download"
                                              >
                                                <img src="/assets/images/download.png" />
                                              </a>
                                              {prop.principle ? (
                                                <button
                                                  type="button"
                                                  class="btn btn-primary mx-1"
                                                  onClick={() =>
                                                    deleteDoc(item.id, doc.id)
                                                  }
                                                  style={{
                                                    width: "46px",
                                                    height: "46px",
                                                    backgroundColor: "#020073",
                                                    padding: 0,
                                                  }}
                                                >
                                                  <i
                                                    class="bi bi-trash"
                                                    style={{
                                                      color: "#fff",
                                                      fontSize: "25px",
                                                    }}
                                                  ></i>
                                                </button>
                                              ) : null}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-contact-2"
                      role="tabpanel"
                      aria-labelledby="nav-contact-tab-okk"
                    >
                      <div className="table-responsive mt-3">
                        <div className="scrollbar-1">
                          <table className="table align-middle file-table">
                            <tbody className="">
                              {item?.techinicalDocument !== null
                                ? item?.techinicalDocument?.stabilityData?.map(
                                    (doc) => (
                                      <tr>
                                        <td width="80%">
                                          <div className="d-flex align-items-center gap-3 cursor-pointer">
                                            <img
                                              src={docImage}
                                              className=""
                                              width="40px"
                                              height="auto"
                                              alt=""
                                            />
                                            <div>
                                              <div className="fileName">
                                                <p
                                                  className="mb-0"
                                                  color="#000"
                                                >
                                                  <b>{doc.name}</b>
                                                </p>
                                              </div>
                                              <div className="d-flex align-items-center  cursor-pointer">
                                                <div
                                                  style={{
                                                    width: 1,
                                                    height: 28,
                                                  }}
                                                />
                                                <div className="">
                                                  <p
                                                    className="mb-0"
                                                    color="#000"
                                                  >
                                                    {dayjs(
                                                      doc.createdAt
                                                    ).format("DD/MM/YY")}{" "}
                                                    | {doc.fileType} |{" "}
                                                    {filesize(doc.fileSize, {
                                                      standard: "jedec",
                                                    })}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>

                                        <td width="20%">
                                          <div className="d-flex align-items-center gap-3 cursor-pointer">
                                            <div
                                              style={{ width: 1, height: 28 }}
                                            />
                                            <div className="">
                                              <a
                                                href={doc.documentUrl}
                                                className="download"
                                              >
                                                <img src="/assets/images/download.png" />
                                              </a>
                                              {prop.principle ? (
                                                <button
                                                  type="button"
                                                  class="btn btn-primary mx-1"
                                                  onClick={() =>
                                                    deleteDoc(item.id, doc.id)
                                                  }
                                                  style={{
                                                    width: "46px",
                                                    height: "46px",
                                                    backgroundColor: "#020073",
                                                    padding: 0,
                                                  }}
                                                >
                                                  <i
                                                    class="bi bi-trash"
                                                    style={{
                                                      color: "#fff",
                                                      fontSize: "25px",
                                                    }}
                                                  ></i>
                                                </button>
                                              ) : null}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-contact-3"
                      role="tabpanel"
                      aria-labelledby="nav-contact-tab-okkk"
                    >
                      <div className="table-responsive mt-3">
                        <div className="scrollbar-1">
                          <table className="table align-middle file-table">
                            <tbody className="">
                              {item?.techinicalDocument !== null
                                ? item?.techinicalDocument?.labelingInformation?.map(
                                    (doc) => (
                                      <tr>
                                        <td width="80%">
                                          <div className="d-flex align-items-center gap-3 cursor-pointer">
                                            <img
                                              src={docImage}
                                              className=""
                                              width="40px"
                                              height="auto"
                                              alt=""
                                            />
                                            <div>
                                              <div className="fileName">
                                                <p
                                                  className="mb-0"
                                                  color="#000"
                                                >
                                                  <b>{doc.name}</b>
                                                </p>
                                              </div>
                                              <div className="d-flex align-items-center  cursor-pointer">
                                                <div
                                                  style={{
                                                    width: 1,
                                                    height: 28,
                                                  }}
                                                />
                                                <div className="">
                                                  <p
                                                    className="mb-0"
                                                    color="#000"
                                                  >
                                                    {dayjs(
                                                      doc.createdAt
                                                    ).format("DD/MM/YY")}{" "}
                                                    | {doc.fileType} |{" "}
                                                    {filesize(doc.fileSize, {
                                                      standard: "jedec",
                                                    })}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>

                                        <td width="20%">
                                          <div className="d-flex align-items-center gap-3 cursor-pointer">
                                            <div
                                              style={{ width: 1, height: 28 }}
                                            />
                                            <div className="">
                                              <a
                                                href={doc.documentUrl}
                                                className="download"
                                              >
                                                <img src="/assets/images/download.png" />
                                              </a>
                                              {prop.principle ? (
                                                <button
                                                  type="button"
                                                  class="btn btn-primary mx-1"
                                                  onClick={() =>
                                                    deleteDoc(item.id, doc.id)
                                                  }
                                                  style={{
                                                    width: "46px",
                                                    height: "46px",
                                                    backgroundColor: "#020073",
                                                    padding: 0,
                                                  }}
                                                >
                                                  <i
                                                    class="bi bi-trash"
                                                    style={{
                                                      color: "#fff",
                                                      fontSize: "25px",
                                                    }}
                                                  ></i>
                                                </button>
                                              ) : null}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className=" align-middle file-table "></table>
                </div>
                <div
                  className="tab-pane fade"
                  id="primary-pills-contact"
                  role="tabpanel"
                >
                  <p>
                    Etsy mixtape wayfarers, ethical wes anderson tofu before
                    they sold out mcsweeney's organic lomo retro fanny pack
                    lo-fi farm-to-table readymade. Messenger bag gentrify
                    pitchfork tattooed craft beer, iphone skateboard locavore
                    carles etsy salvia banksy hoodie helvetica. DIY synth PBR
                    banksy irony. Leggings gentrify squid 8-bit cred pitchfork.
                    Williamsburg banh mi whatever gluten-free, carles pitchfork
                    biodiesel fixie etsy retro mlkshk vice blog. Scenester cred
                    you probably haven't heard of them, vinyl craft beer blog
                    stumptown. Pitchfork sustainable tofu synth chambray yr.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="verticle-tab scrollbar-1">
                <div className="row tab-content">
                  <div className="col-md-12 text-center ">
                    {/* <div className="py-3 Oxygen">
                                            <h2>{item.name}</h2>
                                        </div> */}
                    <h3 style={{ textAlign: "left", color: "#000" }}>
                      <b>Description</b>
                    </h3>
                    <div
                      className="titile-dis"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></div>
                    {/* <p>{item.description}</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default ProductModal;
