import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import AuthService from "../../services/AuthServices";
import { LinearProgress } from "@mui/material";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./style.css";
import "./script.js";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { Useprovider } from "../../dashboard";
import { decodeToken } from "react-jwt";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login() {
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const navigate = useNavigate();
  const param = useSearchParams();
  const location = useLocation();
  console.log("navigate", navigate);
  const pathname = location.pathname;

  const otpValue = {
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  };
  const [otp, setOtp] = useState(otpValue);
  const request = {
    email: "",
    password: "",
    otp: "",
  };
  const [paramCheck, setParaCheck] = useState(location.pathname == "/");
  console.log("useSearchParams", pathname);
  const [data, setData] = useState(request);
  const [showOtp, setShowOtp] = useState(false);

  const [message, setMessage] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };
  const otpHandle = (event) => {
    //  debugger;
    const { name, value } = event.target;
    setOtp({ ...otp, [name]: value });
    console.log(otp);

    // setData({ ...data, otp: otpVal })
    console.log(data);
  };

  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const login = () => {
    debugger;
    const otpVal = `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}${otp.otp5}${otp.otp6}`;
    console.log("otp", data);
    console.log("otp", otpVal);

    if (paramCheck) {
      //    console.log("otp p", !otp.otp1 === false && !otp.otp2 === false && !otp.otp3 === false && !otp.otp4 === false && !otp.otp5 === false && !otp.otp6 === false)
      if (
        (!otp.otp1 === false &&
          !otp.otp2 === false &&
          !otp.otp3 === false &&
          !otp.otp4 === false &&
          !otp.otp5 === false &&
          !otp.otp6 === false) == false
      ) {
        setOpen(true);
        setSeverity("error");
        setMessage("Please Enter Valid OTP");
      } else {
        setLoad(true);
        AuthService.sendOtp({ ...data, otp: otpVal })
          .then((response) => {
            console.log(response.data);
            localStorage.setItem("token", response.data.jwtToken);
            if (localStorage.getItem("token")) {
              navigate("/dashboard/app", {
                state: { pathLink: location.pathname },
              });
            }
          })
          .catch((e) => {
            console.log(e);
            setLoad(false);
            setOpen(true);
            setSeverity("error");
            setMessage(e.response.data.message);
          });
      }
    } else {
      setLoad(true); // Set loading state to true
      AuthService.adminLogin(data)
        .then((response) => {
          debugger;
          console.log("userRole_response", response.data);
          const token = response.data.jwtToken;

          // Decode token to get user role
          const decodedToken = decodeToken(token);
          const userRole = decodedToken.role[0].name;
          console.log("userRole", userRole.name);

          if (userRole === "ROLE_ADMIN") {
            localStorage.setItem("token", token);
            // Redirect to dashboard if user is authorized for Admin role
            navigate("/dashboard/app", {
              state: { pathLink: location.pathname },
            });
          } else {
            // Show error message if user is not authorized
            setOpen(true);
            setSeverity("error");
            setMessage("You are not authorized to perform this action.");
          }
        })
        .catch((error) => {
          debugger;
          console.error("An error occurred during admin login:", error);
          setOpen(true);
          setSeverity("error");
          setMessage(error.response.data.message);
        })
        .finally(() => {
          setLoad(false); // Set loading state to false after login attempt
        });
    }
  };
  const sendOtp = () => {
    debugger;
    if (data.email == "") {
      setOpen(true);
      setSeverity("error");
      setMessage("Please Check Login Credentials");
    } else if (data.password == "") {
      setOpen(true);
      setSeverity("error");
      setMessage("Please Check Login Credentials");
    } else {
      setLoad(true);
      debugger;
      AuthService.login(data)
        .then((response) => {
          console.log("console_reponse_data", response.data);
          setLoad(false);
          setOpen(true);
          setSeverity("success");
          setMessage(response.data.message);
          if (response.data.message == "OTP sent successfully") {
            setShowOtp(true);
          }
          console.log(response.data.message);
        })
        .catch((e) => {
          console.log(e);
          setLoad(false);
          setOpen(true);
          setSeverity("error");
          setMessage(e.response.data.message);
        });
      //}
    }
  };

  //----------------------OTP--------------------
  setTimeout(() => {
    const inputs = document.querySelectorAll(".otp-field input");

    inputs.forEach((input, index) => {
      input.dataset.index = index;
      input.addEventListener("keyup", handleOtp);
      input.addEventListener("paste", handleOnPasteOtp);
    });

    function handleOtp(e) {
      /**
       * <input type="text" 👉 maxlength="1" />
       * 👉 NOTE: On mobile devices `maxlength` property isn't supported,
       * So we to write our own logic to make it work. 🙂
       */
      const input = e.target;
      let value = input.value;
      let isValidInput = value.match(/[0-9a-z]/gi);
      input.value = "";
      input.value = isValidInput ? value[0] : "";

      let fieldIndex = input.dataset.index;
      if (fieldIndex < inputs.length - 1 && isValidInput) {
        input.nextElementSibling.focus();
      }

      if (e.key === "Backspace" && fieldIndex > 0) {
        input.previousElementSibling.focus();
      }

      if (fieldIndex == inputs.length - 1 && isValidInput) {
        //  submit();
      }
    }

    function handleOnPasteOtp(e) {
      const data = e.clipboardData.getData("text");
      const value = data.split("");
      if (value.length === inputs.length) {
        inputs.forEach((input, index) => (input.value = value[index]));
        submit();
      }
    }

    function submit() {
      console.log("Submitting...");
      // 👇 Entered OTP
      let otp = "";
      inputs.forEach((input) => {
        otp += input.value;
        input.disabled = true;
        input.classList.add("disabled");
      });
      console.log(otp);
      // 👉 Call API below
    }
  }, 500);
  //----------------------OTP--------------------
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard/app");
    }
  }, []);
  //-----------------------Password-Show-Hide---------------------
  let passwordInput = document.getElementById("txtPassword"),
    toggle = document.getElementById("btnToggle"),
    icon = document.getElementById("eyeIcon");

  function togglePassword() {
    if (passwordInput.type === "password") {
      passwordInput.type = "text";
      icon.classList.add("fa-eye-slash");
      //toggle.innerHTML = 'hide';
    } else {
      passwordInput.type = "password";
      icon.classList.remove("fa-eye-slash");
      //toggle.innerHTML = 'show';
    }
  }

  function checkInput() {
    //if (passwordInput.value === '') {
    //toggle.style.display = 'none';
    //toggle.innerHTML = 'show';
    //  passwordInput.type = 'password';
    //} else {
    //  toggle.style.display = 'block';
    //}
  }
  // toggle.addEventListener('click', togglePassword, false);
  // passwordInput.addEventListener('keyup', checkInput, false);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      login();
    }
  };

  return (
    <>
      <section className="login-wrapper">
        <div className="container">
          <div className="row login-flex">
            <div className="col-md-5">
              <div className="log-img">
                <img src="./assets/images/side-login.png" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="login-form">
                <div className="login__form">
                  <div className="login__form-wrapper">
                    {/* <ToastContainer /> */}
                    <div className="logo-img mb-5">
                      <img src="./assets/images/login-logo.png" />
                    </div>
                    <h1>SIGN IN {pathname == "/login" ? "ADMIN" : "WITH"}</h1>
                    <p>WELCOME TO NUTRACONNECT</p>
                    <div className="login__field-group">
                      <label className="login__label" for="user-email">
                        Email
                      </label>
                      <input
                        className="login__field mt-2"
                        type="text"
                        placeholder="Enter Your Email"
                        name="email"
                        value={data.email}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <div className="login__field-group mt-3">
                      <label className="login__label" for="pass">
                        Password
                      </label>
                      <input
                        className="login__field mt-2 mb-3"
                        id="txtPassword"
                        type="password"
                        placeholder="Enter Your Password"
                        name="password"
                        value={data.password}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                      />
                      <button
                        type="button"
                        id="btnToggle"
                        class="toggle passwordHideShow"
                        onClick={togglePassword}
                      >
                        <i id="eyeIcon" class="fa fa-eye"></i>
                      </button>
                    </div>
                    <div className="login__field-group login__field-group--horz">
                      <label className="login__label login__label--horz">
                        {/* <input
                          className="login__checkbox"
                          type="checkbox"
                          name="remember_me"
                        />
                        <span>Remember me</span> */}
                      </label>
                      <Link to="/forgotpassword" state={{ pathname: pathname }}>
                        Forgot password ?
                      </Link>
                    </div>

                    {pathname == "/" ? (
                      <button
                        className="login__btn"
                        type="button"
                        data-login="false"
                        onClick={sendOtp}
                      >
                        {" "}
                        <span className="login__btn-label">Send OTP Code</span>
                      </button>
                    ) : (
                      <button
                        className="login__btn"
                        type="button"
                        data-login="false"
                        onClick={login}
                      >
                        {" "}
                        <span className="login__btn-label">Login</span>
                      </button>
                    )}

                    {load ? (
                      <LinearProgress
                        sx={{
                          position: "relative",
                          top: "-5px",
                          backgroundColor: "transparent",
                        }}
                      />
                    ) : null}

                    {pathname == "/" && showOtp ? (
                      <>
                        <div class="login__field-group mt-5">
                          <label className="login__label" for="pass">
                            Enter OTP Code
                          </label>
                          <div class="otp-field mt-2">
                            <input
                              type="text"
                              maxlength="1"
                              name="otp1"
                              value={otp.otp1}
                              onChange={otpHandle}
                              onKeyDown={handleKeyDown}
                            />
                            <input
                              type="text"
                              maxlength="1"
                              name="otp2"
                              value={otp.otp2}
                              onChange={otpHandle}
                              onKeyDown={handleKeyDown}
                            />
                            <input
                              class="space"
                              type="text"
                              maxlength="1"
                              name="otp3"
                              value={otp.otp3}
                              onChange={otpHandle}
                              onKeyDown={handleKeyDown}
                            />
                            <input
                              type="text"
                              maxlength="1"
                              name="otp4"
                              value={otp.otp4}
                              onChange={otpHandle}
                              onKeyDown={handleKeyDown}
                            />
                            <input
                              type="text"
                              maxlength="1"
                              name="otp5"
                              value={otp.otp5}
                              onChange={otpHandle}
                              onKeyDown={handleKeyDown}
                            />
                            <input
                              type="text"
                              maxlength="1"
                              name="otp6"
                              value={otp.otp6}
                              onChange={otpHandle}
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <button
                          className="login__btn"
                          type="button"
                          data-login="false"
                          onClick={login}
                        >
                          {" "}
                          <span className="login__btn-label">Login</span>
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={alertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={alertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Login;
