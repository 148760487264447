import http from "../httpcommon";
// import authHeader from "../services/AuthServices/authHeader";

const getLatestData = (userId) => {
  return http.get(`/dashboard/data/${userId}`);
};

const getRecentData = (userId) => {
  return http.get(`/dashboard/recent/data/${userId}`);
};
//get principal
const getPrincipalLatest = (userId) => {
  return http.get(`dashboard/principal/latest/${userId}`);
};
const getPrincipalRecent = (userId) => {
  return http.get(`dashboard/principal/recent/${userId}`);
};
//get admin
const getAdminLatest = () => {
  return http.get(`/dashboard/admin/latest`);
};
const getAdminRecent = () => {
  return http.get(`dashboard/admin/recent`);
};

//----------------get Dashboard Count---------------------

//Distributor Count
const getDistributorCount = (userId) => {
  return http.get(`dashboard/count/${userId}`);
};
//Principal Count
const getPrincipalCount = (userId) => {
  return http.get(`dashboard/principal/count/${userId}`);
};
//Admin Count
const getAdminCount = () => {
  return http.get(`dashboard/admin/count`);
};

const DashboardService = {
  getRecentData,
  getLatestData,
  getPrincipalLatest,
  getPrincipalRecent,
  getAdminLatest,
  getAdminRecent,
  getDistributorCount,
  getPrincipalCount,
  getAdminCount,
};
export default DashboardService;
