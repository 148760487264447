import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import Snackbar from "@mui/material/Snackbar";
import UserServices from "../services/UserServices";
import * as React from "react";
import Stack from "@mui/material/Stack";
import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddTechDoc(prop) {
  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setFiles("");
    setFileCount(0);
  };
  const handleShow = () => setShow(true);
  const [fileCount, setFileCount] = useState(0);
  const [category, setCategory] = useState("");
  const [files, setFiles] = useState();
  const [load, setLoad] = useState(false);
  //  console.log("prop", prop)
  const [techinicalDataSheet, setTechinicalDataSheet] = useState("");
  //   const [sIDI, setSIDI] = useState("");
  //   const [packagingInformation, setPackagingInformation] = useState("");
  //   const [storageAndHandling, setStorageAndHandling] = useState("");
  //   const [stabilityData, setStabilityData] = useState("");
  //   const [labelingInformation, setLabelingInformation] = useState("");

  //---------------------Input- multi-File-Function--------------------
  let state = {};

  setTimeout(() => {
    const inputFile = document.getElementById("multi-file-input");
    const fileList = document.getElementById("fileList");
    //  console.log("fileList", fileList.children[1])

    function updateState(newState) {
      state = { ...state, ...newState };
      console.log(state);
    }
    if (inputFile) {
      inputFile.addEventListener("change", function (e) {
        let files = inputFile.files;
        setFileCount(files.length);
        let filesArr = Array.from(files);

        updateState({ files: files, filesArr: filesArr });
        //    setFile(state)
        if (Object.keys(state).length !== 0) {
          const stateValue = state.filesArr;
          setFiles(stateValue);

          console.log("formData", state.filesArr, files);
        }
        renderFileList();
      });
    }
    let curArr;
    if (inputFile) {
      fileList.addEventListener("click", function (e) {
        let key = e.target.parentElement.getAttribute("key");

        if (state.filesArr !== undefined) {
          curArr = state.filesArr.slice();
          curArr.splice(key, 1);
          updateState({ filesArr: curArr });
          setFileCount(state.filesArr.length);
          const statefilesArr = state.filesArr;
          setFiles(statefilesArr);
          setProgress(0);
          console.log("state dd", statefilesArr, files);
          renderFileList();
        }
      });
    }

    function renderFileList() {
      let fileMap = state.filesArr.map((file, index) => {
        let suffix = "bytes";
        let size = file.size;
        if (size >= 1024 && size < 1024000) {
          suffix = "KB";
          size = Math.round((size / 1024) * 100) / 100;
        } else if (size >= 1024000) {
          suffix = "MB";
          size = Math.round((size / 1024000) * 100) / 100;
        }

        return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
      });

      fileList.children[1].innerHTML = fileMap.join("");
    }
  }, [1000]);

  const accept =
    ".docx, .doc, .rtf, .txt, .pdf, .html, .odt, .docm, .dotx, .dotm, .odp, .odg, .odc, .odf, .wps, .xlsx, .xls, .xlsm, .csv, .tsv, .ods, .xltx, .xltm, .xlsb, .xlw, .txt, .prn, .slk, .dbf, .dif, .ods, .json, .pptx, .ppt, .pptm, .pps, .ppsx, .potx, .potm, .ppa, .ppam, .xml, .odp, .odg, .gif, .jpg, .jpeg, .png, .jpg, .jpeg, .png, .gif, .bmp, .tiff, .ico, .svg, .webp, .webm, .mp4";

  const [progress, setProgress] = useState(0);

  function addDoc() {
    debugger;
    setProgress(0);
    setLoad(true);
    var formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("documents", files[i]);
      console.log("state dd", files[i]);
    }
    formData.append("productId", prop.id);
    formData.append("productDocumentCategoryId", selectedSubCategory);
    // formData.append("documents ", techinicalDataSheet);
    // formData.append("sIDI", sIDI);
    // formData.append("packagingInformation", packagingInformation);
    // formData.append("storageAndHandling  ", storageAndHandling);
    // formData.append("stabilityData", stabilityData);
    // formData.append("labelingInformation", labelingInformation);

    const formDataObject = {};

    // Iterate over the formData object and populate the formDataObject
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    // // Now you can stringify formDataObject
    console.log("formData", formData);

    UserServices.addTechDoc(formData, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((respons) => {
        console.log("galleryPost", respons);
        setShow(false);
        setLoad(false);
        //  setOpen(true);
        prop.load();
      })
      .catch((e) => {
        setLoad(false);
        setProgress(0);
        console.log(e);
        setOpen(false);
      });
  }

  const [productDocCategoryList, setProductDocCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const getProductDocCategory = () => {
    UserServices.getProductDocCategory()
      .then((response) => {
        setProductDocCategoryList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProductDocCategory();
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(null); // Reset sub-category when category changes
  };

  console.log("selectedSubCategory", selectedSubCategory);
  return (
    <>
      <button
        type="button"
        className="btn btn-primary mx-1"
        onClick={handleShow}
      >
        <i className="bi bi-file-earmark-plus"></i>
      </button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Upload Products Documents</Modal.Title>
        </Modal.Header>
        {load ? <LinearProgress /> : null}
        <Modal.Body>
          <FormControl sx={{ m: 1, minWidth: 255 }}>
            <InputLabel id="demo-simple-select-label">
              Select Document Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="docuemntCategory"
              label="Select Document Category"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              {productDocCategoryList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedCategory && (
            <FormControl sx={{ m: 1, minWidth: 300 }}>
              <InputLabel id="subcategory-label">
                Select Document SubCategory
              </InputLabel>
              <Select
                labelId="subcategory-label"
                id="subcategory"
                label="Select Document SubCategory"
                value={selectedSubCategory}
                onChange={(event) => setSelectedSubCategory(event.target.value)}
              >
                {productDocCategoryList
                  .find((category) => category.id === selectedCategory)
                  .subDocCategories?.map((subCategory) => (
                    <MenuItem key={subCategory.id} value={subCategory.id}>
                      {subCategory.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {selectedSubCategory && (
            <div className="file-input mt-2" style={{ marginLeft: "9px" }}>
              <label className="file-input__label" for="file-input">
                <input
                  type="file"
                  id="multi-file-input"
                  class="file-input__input"
                  multiple
                  accept={accept}
                  //onChange={(e) => setFile(e.target.files)}
                />
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="upload"
                  class="svg-inline--fa fa-upload fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                  ></path>
                </svg>
                <span>Upload Documents</span>
              </label>
            </div>
          )}
          <br />
          <div
            className="files"
            id="fileList"
            style={
              fileCount > 0
                ? { display: "block", marginLeft: "10px" }
                : { display: "none" }
            }
          >
            <h5>Files Selected</h5>
            <ul className="Filelisting"></ul>
          </div>
          {files && (
            <div className="progress my-3" style={{ marginLeft: "10px" }}>
              <div
                className="progress-bar progress-bar-info"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progress + "%" }}
              >
                {progress}%
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button variant="primary" onClick={addDoc}>
            Save & Update
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Stack sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={alertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={alertClose} severity="success" sx={{ width: "100%" }}>
            Files Uploaded Successfully
          </Alert>
        </Snackbar>
      </Stack> */}
    </>
  );
}

export default AddTechDoc;
