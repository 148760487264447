import http from "../httpcommon";
import authHeader from "./auth-header";

const getCurrencies = () => {
  return http.get(`/admin/currencies`);
};

const CurrencyServices = {
  getCurrencies,
};
export default CurrencyServices;
