import { useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import jwt_decode from "jwt-decode";
import AuthService from "../../services/AuthServices";

const ChangePassword = () => {

  var jwtToken = localStorage.getItem("token");

  const jwtDecode = jwtToken ? jwt_decode(jwtToken) : null;
  const email = jwtDecode ? jwtDecode.sub : null;

  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [validatedOtp, setValidatedOtp] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [optLoad, setOptLoad] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const dataJson = {
    email: email,
    oldPassword : "",
    newPassword : "",
    confirmPassword : ""
  };

  const [data, setData] = useState(dataJson);

  const setValue = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });

     // Check if newPassword and confirmPassword match
     if (name === 'newPassword' || name === 'confirmPassword') {
      setPasswordsMatch(data.newPassword === value || data.confirmPassword === value);
    }
    console.log(data)
  };

  const resetPassword = () => {
    setOptLoad(true);
    AuthService.changePassword({...data, email: email})
      .then((respons) => {
        setOptLoad(false);
        if (respons.data.success === true) {
          setData({
            ...data,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
          setValidatedOtp(false);
          setOpen(true);
          setMessage(respons.data.message);
          setSeverity("success");

        }
      })
      .catch((e) => {
        setOptLoad(false);
        if (e.response.status == 401 || e.response.status == 404 || e.response.status == 400) {
          console.log(e.response.status)
          setOpen(true);
          setMessage(e.response.data.message);
          setSeverity("error");
        }
      });
  };
  const resetPasswordSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (
        data.oldPassword !== "" &&
        data.newPassword !== "" &&
        data.confirmPassword !== ""
      ) {
        if (data.newPassword === data.confirmPassword) {
          resetPassword();
        } else {
          setOpen(true);
          setMessage("New Password & Confirm Password is not same");
          setSeverity("error");
        }
      }else{
        setOpen(true);
        setSeverity("error");
        setMessage("Please Fill all details");
      }
    }
    setValidatedOtp(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <h5 className="mb-0 font-18">Change Password</h5>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 text-right">

                </div>
                <div className="col">
                  <Form
                    className="form-body pt-2"
                    noValidate
                    validated={validatedOtp}
                  >
                    <div className="col-12 mt-3">
                      <Form.Group
                        controlId="validationCustom01"
                        className="ms-auto position-relative"
                      >
                        <Form.Control
                          className="form-control radius-4 height-50"
                          required
                          type="password"
                          placeholder="Old Password"
                          name="oldPassword"
                          value={data.oldPassword}
                          onChange={setValue}
                        />
                        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                      </Form.Group>

                    </div>
                    <div className="col-12 mt-3">
                      <Form.Group
                        controlId="validationCustom01"
                        className="ms-auto position-relative"
                      >
                        <Form.Control
                          className={`form-control radius-4 height-50 ${!passwordsMatch && data.confirmPassword ? 'is-invalid' : ''}`}
                          required
                          type="password"
                          placeholder="Enter New password"
                          name="newPassword"
                          maxLength={30}
                          // minLength={8}
                          value={data.newPassword}
                          onChange={setValue}
                        />
                        <Form.Control.Feedback type="invalid">
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-12 mt-3">
                      <Form.Group
                        controlId="validationCustom01"
                        className="ms-auto position-relative"
                      >
                        
                        <Form.Control
                          className={`form-control radius-4 height-50 ${!passwordsMatch && data.confirmPassword ? 'is-invalid' : ''}`}
                          required
                          type="password"
                          placeholder="Enter Confirm password"
                          name="confirmPassword"
                          maxLength={30}
                          // minLength={8}
                          value={data.confirmPassword}
                          onChange={setValue}
                        />
                        <Form.Control.Feedback type="invalid">
                          {passwordsMatch ? 'Please provide a valid password.' : 'New Password & Confirm Password are not the same.'}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Form>
                  <div className="col-12 mt-3">
                    <div className="d-grid">
                      <button
                        to="/dashboard/admin/home"
                        type="submit"
                        className="btn btn-primary login-btn height-50"
                        onClick={resetPasswordSubmit}
                      >
                        {optLoad ?
                          `Please Wait...`
                          :
                          "Change Password"
                        }

                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>

    </>
  )
}

export default ChangePassword