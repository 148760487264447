import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "../../services/AuthServices";
import { LinearProgress } from "@mui/material";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./style.css";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function GetOTP() {
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const param = useSearchParams();
  const location = useLocation();
  console.log("useSearchParams", location);
  const pathname = location.pathname;
  const pathLink = location.state.pathname;
  const [message, setMessage] = useState("");
  const email = location.state.email;
  const request = {
    email: email,
    //  password: ""
  };

  const [data, setData] = useState(request);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const otpValue = {
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  };
  const [otp, setOtp] = useState(otpValue);
  const [val, setVal] = useState("");
  const numberValidated = (e) => {
    const regex = /^[0-9\b]+$/;
    console.log("numberValidated", regex.test(e.target.value));
    if (e.target.value === "" || regex.test(e.target.value) == true) {
      setVal(e.target.value);
    }
  };
  const otpHandle = (event) => {
    //  debugger;
    const regex = /^[0-9\b]+$/;
    const { name, value } = event.target;
    if (event.target.value === "" || regex.test(event.target.value) == true) {
      setOtp({ ...otp, [name]: value });
    } else {
      setOpen(true);
      setMessage("Please Enter Number Only");
    }

    console.log(otp);

    // setData({ ...data, otp: otpVal })
    console.log(data);
  };

  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const login = () => {
    const otpVal = `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}${otp.otp5}${otp.otp6}`;
    if (
      (!otp.otp1 === false &&
        !otp.otp2 === false &&
        !otp.otp3 === false &&
        !otp.otp4 === false &&
        !otp.otp5 === false &&
        !otp.otp6 === false) == false
    ) {
      setOpen(true);
      setMessage("Please enter 6 digit number");
    } else {
      setLoad(true);
      //    debugger;
      AuthService.sendOtp({ ...data, otp: otpVal })
        .then((response) => {
          console.log(response.data);
          navigate("/resetPassword", {
            state: {
              pathname: location.state.pathname,
              email: location.state.email,
            },
          });
          //navigate(pathLink)
          // if (localStorage.getItem("token")) {
          //     navigate("/dashboard/app", { state: { pathLink: location.pathname } })
          // }
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status == 400) {
            setOpen(true);
            setMessage(e.response.data.message);
          }
          setLoad(false);
        });
    }
  };
  setTimeout(() => {
    const inputs = document.querySelectorAll(".otp-field input");

    inputs.forEach((input, index) => {
      input.dataset.index = index;
      input.addEventListener("keyup", handleOtp);
      input.addEventListener("paste", handleOnPasteOtp);
    });

    function handleOtp(e) {
      /**
       * <input type="text" 👉 maxlength="1" />
       * 👉 NOTE: On mobile devices `maxlength` property isn't supported,
       * So we to write our own logic to make it work. 🙂
       */
      const input = e.target;
      let value = input.value;
      let isValidInput = value.match(/[0-9a-z]/gi);
      input.value = "";
      input.value = isValidInput ? value[0] : "";

      let fieldIndex = input.dataset.index;
      if (fieldIndex < inputs.length - 1 && isValidInput) {
        input.nextElementSibling.focus();
      }

      if (e.key === "Backspace" && fieldIndex > 0) {
        input.previousElementSibling.focus();
      }

      if (fieldIndex == inputs.length - 1 && isValidInput) {
        //  submit();
      }
    }

    function handleOnPasteOtp(e) {
      const data = e.clipboardData.getData("text");
      const value = data.split("");
      if (value.length === inputs.length) {
        inputs.forEach((input, index) => (input.value = value[index]));
        submit();
      }
    }

    function submit() {
      console.log("Submitting...");
      // 👇 Entered OTP
      let otp = "";
      inputs.forEach((input) => {
        otp += input.value;
        input.disabled = false;
        // input.classList.add("disabled");
      });
      console.log(otp);
      // 👉 Call API below
    }
  }, 500);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard/app");
    }
  }, []);

  return (
    <>
      <section className="login-wrapper">
        <div className="container">
          <div className="row login-flex">
            <div className="col-md-5">
              <div className="log-img">
                <img src="./assets/images/side-login.png" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="login-form">
                <div className="login__form">
                  <div className="login__form-wrapper">
                    <div className="logo-img mb-5">
                      <img src="./assets/images/login-logo.png" />
                    </div>
                    <p>Please Check your Email</p>

                    <h1>Enter OTP</h1>

                    <div class="otp-field mt-2">
                      <input
                        type="text"
                        maxlength="1"
                        name="otp1"
                        value={otp.otp1}
                        onChange={otpHandle}
                      />
                      <input
                        type="text"
                        maxlength="1"
                        name="otp2"
                        value={otp.otp2}
                        onChange={otpHandle}
                      />
                      <input
                        class="space"
                        type="text"
                        maxlength="1"
                        name="otp3"
                        value={otp.otp3}
                        onChange={otpHandle}
                      />
                      <input
                        type="text"
                        maxlength="1"
                        name="otp4"
                        value={otp.otp4}
                        onChange={otpHandle}
                      />
                      <input
                        type="text"
                        maxlength="1"
                        name="otp5"
                        value={otp.otp5}
                        onChange={otpHandle}
                      />
                      <input
                        type="text"
                        maxlength="1"
                        name="otp6"
                        value={otp.otp6}
                        onChange={otpHandle}
                      />
                    </div>

                    {/* <h1 className="mt-3">New Password</h1>
                                    <div className="login__field-group mt-3">

                                        <input
                                            className="login__field mt-2 mb-3"

                                            type="password "
                                            placeholder="Enter Your New Password"
                                            name="password"
                                            value={data.password}
                                            onChange={handleInputChange}
                                        />
                                    </div> */}

                    <button
                      className="login__btn mt-3"
                      type="button"
                      data-login="false"
                      onClick={login}
                    >
                      {" "}
                      <span className="login__btn-label">Verify Password</span>
                    </button>
                    {load ? (
                      <LinearProgress
                        sx={{
                          position: "relative",
                          top: "-5px",
                          backgroundColor: "transparent",
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={alertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={alertClose} severity="error" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default GetOTP;
