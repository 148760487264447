import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import settingService from "./settingServices";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const Application = () => {
  const [applicationData, setApplicationData] = useState({
    fullname: "",
    shortName: "",
    applicationUrl: "",
    apiUrl: "",
    facebookUrl: "",
    youtubeUrl: "",
    instagramUrl: "",
    adminUrl: "",
    documentUrl: "",
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const getApplicationData = () => {
    settingService.GetApplicationServices().then((response) => {
      const { id, ...filteredData } = response.data;
      setApplicationData(filteredData);
    });
  };

  const handleSave = () => {
    const requiredFields = Object.keys(applicationData);
    const emptyFields = requiredFields.filter(
      (field) => !applicationData[field]
    );

    if (emptyFields.length > 0) {
      setOpen(true);
      return;
    }

    const data = { ...applicationData };

    swal({
      title: "Are you sure?",
      text: "You want to update the application settings?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        settingService
          .PutApplicationServices(data)
          .then((response) => {
            getApplicationData();
            swal("Successful", { icon: "success" });
          })
          .catch((error) => {
            console.error("Error updating application settings:", error);
            toast.error("Error updating application settings");
          })
          .finally(() => setLoading(false));
      }
    });
  };

  useEffect(() => {
    getApplicationData();
  }, []);

  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <div className="col-12 col-lg-12 col-xl-12 d-flex">
        <div className="card w-100 rounded-4">
          <div className="card-body">
            <Stack spacing={2} sx={{ width: "100%" }}>
              <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={alertClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert
                  onClose={alertClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  Fields are empty
                </Alert>
              </Snackbar>
            </Stack>

            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2 className="mb-0 dash-heading">Application Information</h2>
            </div>

            {Object.entries(applicationData).map(([key, value]) => (
              <div key={key} className="mb-3">
                <label
                  htmlFor={`${key}Field`}
                  className="form-label"
                  style={{ fontWeight: "bold" }}
                >
                  {key.charAt(0).toUpperCase() +
                    key
                      .slice(1)
                      .split(/(?=[A-Z])/)
                      .join(" ")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={`${key}Field`}
                  placeholder={`${key.split(/(?=[A-Z])/).join(" ")}`}
                  value={value}
                  onChange={(e) =>
                    setApplicationData((prevData) => ({
                      ...prevData,
                      [key]: e.target.value,
                    }))
                  }
                  style={{ border: "1px solid #ccc", borderRadius: "3px" }}
                />
              </div>
            ))}
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Application;
