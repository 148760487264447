import http from "../../httpcommon";
import authHeader from "../../services/auth-header";

const CountryServices = (page, pageSize) => {
  console.log("counter here");
  console.log("country-services", page, pageSize);
  return http.get(`country/allCountries`, {
    headers: {
      ...authHeader(),
    },
  });
};

const CurrencyServices = (page, pageSize) => {
  console.log("counter here");
  console.log("country-services", page, pageSize);
  return http.get(`admin/currencies/list`, {
    headers: {
      ...authHeader(),
    },
  });
};

//fetching all contries List

const CurrencyCountriesList = () => {
  console.log("counter here");
  console.log("country-services");
  return http.get(`country/allCountries`);
};

const CurrencyUpdate = (page, pageSize) => {
  return http.post(`admin/currency/update`, {
    headers: {
      ...authHeader(),
    },
  });
};

//update Template
const putTemplateUpdate = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return http.put(`admin/updatedownloadtemplate/${id}`, formData, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};

// //Save New Template
// const postTemplateSave = (templateType, file) => {
//   debugger;
//   const formData = new FormData();
//   formData.append("documentType", templateType);
//   formData.append("file", file);
//   console.log("file", file);
//   return http.post(`admin/savedownloadtemplate`, formData, {
//     headers: {
//       ...authHeader(),
//     },
//   });
// };

//API to send  notification
const SMTPServices = (email) => {
  console.log("mail", email);
  return http.post(`Smtp/testnotification/${email}`);
};

const CurrencyAdd = (data) => {
  console.log("currency-name", data);
  return http.post(`admin/currency/save`, data);
};

const CurrencyStatus = (id, e) => {
  return http.put(`/admin/currency/updateStatus/${id}/${e}`);
};

//get comapny records
const GetCompanyServices = () => {
  return http.get(`settings/company`);
};

//put company records
const PutCompanyServices = (data) => {
  console.log("companydata", data);
  return http.put(`settings/company`, data);
};

// get application records
const GetApplicationServices = () => {
  return http.get(`settings/application`);
};

//save application settings
const PutApplicationServices = (data) => {
  return http.put(`settings/application`, data);
};

const settingService = {
  CountryServices,
  CurrencyUpdate,
  CurrencyAdd,
  CurrencyStatus,
  CurrencyCountriesList,
  SMTPServices,
  GetCompanyServices,
  PutCompanyServices,
  GetApplicationServices,
  PutApplicationServices,
  CurrencyServices,
  putTemplateUpdate,
  // postTemplateSave,
};
export default settingService;
