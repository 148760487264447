import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "../../services/AuthServices";
import { LinearProgress } from "@mui/material";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ResetPassword() {
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const param = useSearchParams();
  const location = useLocation();
  const pathLink = location.state.pathname;
  console.log("useSearchParams", pathLink);
  const pathname = location.pathname;
  const request = {
    email: location.state.email,
    password: "",
  };

  const [data, setData] = useState(request);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const forgetPassword = () => {
    if (data.password == "") {
      setOpen(true);
    } else {
      setLoad(true);
      //    debugger;
      AuthService.resetPassword(data)
        .then((response) => {
          console.log(response.data);
          navigate(pathLink, {
            state: { email: data.email, pathname: pathLink },
          });
        })
        .catch((e) => {
          console.log(e);
          setLoad(false);
        });
    }
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard/app");
    }
  }, []);

  return (
    <>
      <section className="login-wrapper">
        <div className="container">
          <div className="row login-flex">
            <div className="col-md-5">
              <div className="log-img">
                <img src="./assets/images/side-login.png" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="login-form">
                {/* <form className="login__form"> */}
                <div className="login__form-wrapper">
                  <div className="logo-img mb-5">
                    <img src="./assets/images/login-logo.png" />
                  </div>
                  <h2>Reset your Password</h2>

                  <div className="login__field-group">
                    <input
                      className="login__field mt-2"
                      type="text"
                      placeholder="Enter New Password"
                      name="password"
                      value={data.password}
                      onChange={handleInputChange}
                    />
                  </div>

                  <button
                    className="login__btn"
                    type="button"
                    data-login="false"
                    onClick={forgetPassword}
                  >
                    {" "}
                    <span className="login__btn-label">Change Password</span>
                  </button>
                  {load ? (
                    <LinearProgress
                      sx={{
                        position: "relative",
                        top: "-5px",
                        backgroundColor: "transparent",
                      }}
                    />
                  ) : null}
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={alertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={alertClose} severity="error" sx={{ width: "100%" }}>
            Please Enter New Password
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default ResetPassword;
