import React, { useEffect, useState } from "react";
import settingServices from "./settingServices";
import swal from "sweetalert";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import UserServices from "../../services/UserServices";

const SMTP = () => {
  const [name, setName] = useState("");
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [enableSsl, setEnableSsl] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setMail] = useState("");
  const [id, setID] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    getSmtp();
  }, []);

  //get SMTP records
  function getSmtp() {
    UserServices.smtpGet().then((response) => {
      const data = response.data.data;
      setID(data.id);
      setName(data.username);
      setHost(data.host);
      setPort(data.port);
      setEnableSsl(data.enableSsl);
      setPassword(data.password);
    });
  }

  // put SMTP records
  const handleSave = () => {
    // Validation for fields
    if (!name || !password || !port || !host) {
      setSnackbarData({
        open: true,
        severity: "error",
        message: "Some fields are empty.",
      });
      return;
    }

    const data = {
      id: id,
      host: host,
      port: port,
      username: name,
      password: password,
      enableSsl: enableSsl,
    };

    swal({
      title: "Are you sure?",
      text: "You want to update the SMTP settings?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true); // Start loader
        UserServices.smtpUpdate(data)
          .then(() => {
            getSmtp();
            setSnackbarData({
              open: true,
              severity: "success",
              message: "Data saved successfully!",
            });
          })
          .catch(() => {
            setSnackbarData({
              open: true,
              severity: "error",
              message: "Failed to update SMTP settings.",
            });
          })
          .finally(() => setLoading(false)); // Stop loader
      }
    });
  };

  const handelSubmit = () => {
    if (!email) {
      setSnackbarData({
        open: true,
        severity: "error",
        message: "Email field is empty.",
      });
      return;
    }

    setLoading(true); // Start loader

    // Check if enableSsl is true
    if (enableSsl) {
      setSnackbarData({
        open: true,
        severity: "error",
        message: "Failed to send mail. Please try again",
      });

      setLoading(false);
      return;
    }

    //post - test notification
    settingServices.SMTPServices(email).then((response) => {
      setTimeout(() => {
        if (response && response.data && response.data.success) {
          setSnackbarData({
            open: true,
            severity: "success",
            message: "Mail sent successfully!",
          });
        } else {
          setSnackbarData({
            open: true,
            severity: "error",
            message: "Failed to send mail. Please try again.",
          });
        }
        setLoading(false); // Stop loader
      }, 2000);
    });
  };

  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({ ...snackbarData, open: false });
  };

  return (
    <div className="col-12 col-lg-12 col-xl-12 d-flex">
      <div className="card w-100 rounded-4">
        <div className="card-body">
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={snackbarData.open}
              autoHideDuration={5000}
              onClose={alertClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={alertClose}
                severity={snackbarData.severity}
                sx={{ width: "100%" }}
              >
                {snackbarData.message}
              </Alert>
            </Snackbar>
          </Stack>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 className="mb-0 dash-heading"> SMTP Setting</h2>
          </div>
          <div className="mb-3">
            <label
              htmlFor="exampleInputEmail1"
              className="form-label"
              style={{ fontWeight: "bold" }}
            >
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Email"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ border: "1px solid #ccc", borderRadius: "3px" }}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="exampleInputPassword1"
              className="form-label"
              style={{ fontWeight: "bold" }}
            >
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ border: "1px solid #ccc", borderRadius: "3px" }}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="hostfield"
              className="form-label"
              style={{ fontWeight: "bold" }}
            >
              Host
            </label>
            <input
              type="text"
              className="form-control"
              id="hostfield"
              aria-describedby="emailHelp"
              placeholder="Host"
              value={host}
              onChange={(e) => setHost(e.target.value)}
              style={{ border: "1px solid #ccc", borderRadius: "3px" }}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="portfields"
              className="form-label"
              style={{ fontWeight: "bold" }}
            >
              Port
            </label>
            <input
              type="text"
              className="form-control"
              id="portfields"
              placeholder="Port"
              value={port}
              onChange={(e) => setPort(e.target.value)}
              style={{ border: "1px solid #ccc", borderRadius: "3px" }}
            />
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="sslcheckbox"
              checked={enableSsl}
              onChange={(e) => setEnableSsl(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="sslcheckbox">
              Enable SSL
            </label>
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSave}
          >
            Save
          </button>

          <div style={{ marginBottom: "20px", marginTop: "50px" }}>
            <h2 className="mb-0 dash-heading">Send Test Notification</h2>
          </div>
          <div
            style={{
              border: "1px solid #ccc",
              padding: "15px",
              borderRadius: "5px",
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="emailfield"
                className="form-label"
                style={{ fontWeight: "bold" }}
              >
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Email"
                onChange={(e) => setMail(e.target.value)}
                style={{ border: "1px solid #ccc", borderRadius: "3px" }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                type="submit"
                className="btn btn-warning"
                onClick={handelSubmit}
              >
                Send Mail
              </button>
              {email && loading && (
                <CircularProgress
                  style={{ marginLeft: "10px", size: "8px" }} // Adjust the margin as needed
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMTP;
