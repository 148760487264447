import http from '../httpcommon';
import authHeader from "./auth-header";

const login = (data) => {
    // debugger;
    console.log(data)
    return http.post(`/auth/login`, data);
}

const loginTest = (id) => {
    return http.post(`/auth/admin/login/${id}`, {
        headers: {
            ...authHeader(),
            "Content-Type": "multipart/form-data",
        },
    });
}
const adminLogin = (data) => {
    return http.post(`/auth/admin/login`, data);
}
const sendOtp = (data) => {
    // debugger;
    console.log("verify", data)
    return http.post(`/api/users/verify`, data);
}
const forgetPassword = (data) => {
    return http.post(`/api/users/forgotpassword`, data);
}
const resetPassword = (data) => {
    console.log(data)
    return http.get(`/api/users/resetpassword?email=${data.email}&password=${data.password}`);
}

const changePassword = (data) =>{
    return http.post(`/api/users/changepassword`,data, {
        headers: {
            ...authHeader()
           
        },
    });
}

const getUser = (id) =>{
    return http.get(`/api/users/${id}`)
}

const AuthService = {
    login,
    loginTest,
    adminLogin,
    sendOtp,
    forgetPassword,
    resetPassword,
    changePassword,
    getUser
}

export default AuthService;