import { createContext, useRef, useState } from "react";
import SideNav from "./sideNav/sideNav";
import TopNav from "./topNav/topNav";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
export const Useprovider = createContext();

function Index() {
  const scriptAddedRef = useRef(false);
  useEffect(() => {
    if (!scriptAddedRef.current) {
      const addScript = document.createElement("script");
      addScript.innerHTML = `
            $(".toggle-icon").click(function () {
               
                $(".wrapper").hasClass("toggled") ? ($(".wrapper").removeClass("toggled"), $(".sidebar-wrapper").unbind("hover")) : ($(".wrapper").addClass("toggled"),
                    $(".sidebar-wrapper").hover(function () {
                        //	$(".wrapper").addClass("sidebar-hovered")
                    },
                        function () {
                            $(".wrapper").removeClass("sidebar-hovered")
                        }))
            })
            `;
      document.body.appendChild(addScript);
      scriptAddedRef.current = true;
    }
  }, []);
  const location = useLocation();
  // console.log("path link", location.state.pathLink)
  const [linkVlaue, setLinkVlaue] = useState("");
  useEffect(() => {
    if (location.state == null) {
      setLinkVlaue(localStorage.getItem("pathLink"));
    } else {
      localStorage.setItem("pathLink", location.state.pathLink);
      setLinkVlaue(localStorage.getItem("pathLink"));
    }
  }, []);
  console.log("path link", "out", linkVlaue);

  return (
    <>
      <Useprovider.Provider value={{ path: [linkVlaue, setLinkVlaue] }}>
        <div className="wrapper">
          <TopNav />
          <SideNav />
          <main class="page-content">
            <Outlet />
          </main>
        </div>
      </Useprovider.Provider>
    </>
  );
}

export default Index;
