import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Login from "./auth/login/login";
import Index from "./dashboard";
import Dashboard from "./dashboard/dashboard";
import Gallery from "./dashboard/gallery";
import Users from "./dashboard/manageUsers";
import Order from "./dashboard/order/order";
import ForgetPassword from "./auth/login/forgetPassword";
import GetOTP from "./auth/login/getOTP";
import ResetPassword from "./auth/login/resetPassword";
import SampleOrder from "./dashboard/sample/order";
import Payment from "./dashboard/payment/Payment";
import Complaint from "./dashboard/complaint/Complaint";
import TechnicalQuery from "./dashboard/technicalquery/TechnicalQuery";
import DcNote from "./dashboard/dcNote/dcNote";
import SalesPipeline from "./dashboard/salesPipeline/salesPipeline";
import Budget from "./dashboard/budget/Budget";
import Forecast from "./dashboard/forecast/Forecast";
import Country from "./dashboard/settings/country";
import SMTP from "./dashboard/settings/smtpSetting";
import Currency from "./dashboard/settings/currency";
import Company from "./dashboard/settings/company";
import Application from "./dashboard/settings/application";
import Template from "./dashboard/settings/template";
import Page404 from "./Page404";
import PrivateRoute from "./PrivateRoute";
import ChangePassword from "./auth/login/changePassword";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    index: true,
  },
  {
    path: "admin",
    element: <Login />,
  },
  {
    path: "forgotpassword",
    element: <ForgetPassword />,
  },
  {
    path: "getOTP",
    element: <GetOTP />,
  },
  {
    path: "ResetPassword",
    element: <ResetPassword />,
  },
  {
    path: "/dashboard",
    element: <Index />,
    children: [
      { element: <Navigate to="/dashboard/app" /> },
      {
        path: "app",
        element: <PrivateRoute element={<Dashboard />} fallbackPath={"/"} />,
      },
      {
        path: "gallery",
        element: <PrivateRoute element={<Gallery />} fallbackPath={"/"} />,
      },
      {
        path: "order",
        element: <PrivateRoute element={<Order />} fallbackPath={"/"} />,
      },
      {
        path: "user",
        element: <PrivateRoute element={<Users />} fallbackPath={"/"} />,
      },
      {
        path: "sample",
        element: <PrivateRoute element={<SampleOrder />} fallbackPath={"/"} />,
      },
      {
        path: "payment",
        element: <PrivateRoute element={<Payment />} fallbackPath={"/"} />,
      },
      {
        path: "complaint",
        element: <PrivateRoute element={<Complaint />} fallbackPath={"/"} />,
      },
      {
        path: "technicalquery",
        element: (
          <PrivateRoute element={<TechnicalQuery />} fallbackPath={"/"} />
        ),
      },
      {
        path: "dcnote",
        element: <PrivateRoute element={<DcNote />} fallbackPath={"/"} />,
      },
      {
        path: "salesPipeline",
        element: (
          <PrivateRoute element={<SalesPipeline />} fallbackPath={"/"} />
        ),
      },
      {
        path: "budget",
        element: <PrivateRoute element={<Budget />} fallbackPath={"/"} />,
      },
      {
        path: "forecast",
        element: <PrivateRoute element={<Forecast />} fallbackPath={"/"} />,
      },
      {
        path: "country",
        element: <PrivateRoute element={<Country />} fallbackPath={"/"} />,
      },
      {
        path: "smtp",
        element: <PrivateRoute element={<SMTP />} fallbackPath={"/"} />,
      },
      {
        path: "currency",
        element: <PrivateRoute element={<Currency />} fallbackPath={"/"} />,
      },
      {
        path: "company",
        element: <PrivateRoute element={<Company />} fallbackPath={"/"} />,
      },
      {
        path: "application",
        element: <PrivateRoute element={<Application />} fallbackPath={"/"} />,
      },
      {
        path: "template",
        element: <PrivateRoute element={<Template />} fallbackPath={"/"} />,
      },
      {
        path: "changepassword",
        element: <PrivateRoute element={<ChangePassword />} fallbackPath={"/"} />,
      },
    ],
  },
  { path: "*", element: <Page404 /> },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
