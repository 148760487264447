import http from "../../httpcommon";
import authHeader from "../../services/auth-header";

const uploadProfile = (formData) => {
    return http.put(`/api/users/profile/photo`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            ...authHeader(),
        },
    });
};
  

const TopNavServices = {
    uploadProfile,
};
export default TopNavServices;
