import http from "../httpcommon";
import authHeader from "./auth-header";

const getAssignedPrincipalById = (id) => {
  return http.get(`/api/users/principle/assigned/getall?userId=${id}`);
};

const DistributorServices = {
  getAssignedPrincipalById,
};
export default DistributorServices;
