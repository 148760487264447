import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { DataGrid } from "@mui/x-data-grid";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { Autocomplete, Box, Switch, TextField } from "@mui/material";
import settingService from "./settingServices";
import CircularProgress from "@mui/material/CircularProgress";

const Currency = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    currency: "",
    symbol: "",
    status: false,
  });
  const [gellaryData, setGellaryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState([]);
  const [validated, setValidated] = useState(false);
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [count, setCount] = useState(1);

  const role = "ROLE_Admin";

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },

    {
      field: "name",
      headerName: "Currency Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "currency",
      headerName: "Currency Code",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "symbol",
      headerName: "Symbol",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        function approved(e) {
          swal({
            title: "Are you sure?",
            text: `You want to ${param.row.status ? "Inactive" : "Active"} ${
              param.row.name
            }`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              settingService
                .CurrencyStatus(param.row.id, e)
                .then((response) => {
                  console.log("response_data", response);
                  getGellaryData();
                  swal("Successful", {
                    icon: "success",
                  });
                });
            }
          });
        }
        return (
          <Switch
            checked={param.value}
            onChange={(e) => approved(e.target.checked)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    // Fetch initial data
    getGellaryData(0, 10);
    getAllCountriesList();
  }, []);

  const getGellaryData = async (page, pageSize) => {
    try {
      const response = await settingService.CurrencyServices(page, pageSize);
      console.log("get_currency_response", response);
      const { data } = response;
      console.log("get_currency", data);
      setGellaryData(data);
      setCount(1); // Reset count to 1 when fetching new data
      console.log("response_data_curr", data);
      console.log("fetchedData", data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setGellaryData([]);
      setCount(1);
      setLoading(false);
    }
  };

  const getAllCountriesList = async () => {
    try {
      const response = await settingService.CurrencyCountriesList();
      console.log("response_data", response);
      const countries = response.data.data;
      console.log("countries", countries);
      setCountry(countries);
      console.log("countries-list", countries);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
    setFormData({
      name: "",
      symbol: "",
      status: false,
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAddCurrency = () => {
    setLoad(true);

    const countryExists = gellaryData.some(
      (item) => item.name === formData.name
    );

    if (countryExists) {
      swal({
        title: "Currency Already Exists",
        text: "The selected country already exists in the list.",
        icon: "warning",
        button: "Ok",
      });
      setLoad(false);
    } else {
      settingService
        .CurrencyAdd({ name: formData.name })
        .then((response) => {
          console.log("currency_add_response", response);
          setLoad(false);
          getGellaryData();
          swal({
            title: "Done",
            text: "New Currency has been Added",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (!formData.name) {
        setOpen(true);
      } else {
        handleAddCurrency();
        getGellaryData();
        handleCloseModal();
      }
    }
    setValidated(true);
  };

  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="col-12 col-lg-12 col-xl-12 d-flex">
      <div className="card w-100 rounded-4">
        <div className="card-body">
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={open}
              autoHideDuration={5000}
              onClose={alertClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={alertClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                No country selected
              </Alert>
            </Snackbar>
          </Stack>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 className="mb-0 dash-heading">Currency Settings</h2>
            <Button variant="success" onClick={handleShowModal}>
              <i className="fa fa-plus"></i> Add New Currency
            </Button>
          </div>

          <Modal
            show={showModal}
            onHide={handleCloseModal}
            size={"md"}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add New Currency</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form noValidate validated={validated}>
                <Row className="mb-3">
                  <Col>
                    <Autocomplete
                      id="principalfilter"
                      sx={{ width: "100%" }}
                      options={country}
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        if (value) {
                          setFormData((prevData) => ({
                            ...prevData,
                            name: value.name,
                          }));
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search by Country"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "Search by Country",
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="row my-3">
            {gellaryData.length > 0 ? (
              <DataGrid
                rows={gellaryData}
                columns={columns}
                hideFooterPagination
                hideFooterSelectedRowCount
                loading={loading}
              />
            ) : (
              <Box
                sx={{
                  height: 600,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <p>
                    <b>No Data Available</b>
                  </p>
                )}
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Currency;
