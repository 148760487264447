// PrivateRoute.js
import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";

const PrivateRoute = ({ element, fallbackPath }) => { 
  const isLoggedIn = !!localStorage.getItem("token");

  return (
    <Routes>
      <Route
        path="/"
        element={isLoggedIn ? element : <Navigate to={fallbackPath} replace />}
      />
    </Routes>
  );
};

export default PrivateRoute;
