import { useEffect, useState } from "react";
import UserServices from "../../services/UserServices";
import { DataGrid } from "@mui/x-data-grid";
import settingService from "./settingServices";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../style.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import jwt_decode from "jwt-decode";
import Docread from "./docread";
import OrderStatus from "./orderStatus";
import DistributorServices from "../../services/DistributorServices";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import swal from "sweetalert";
import AdminServices from "../../services/AdminServices";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Country() {
  const [gellaryData, setGellaryData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [principalList, setPrincipalList] = useState([]);
  const [principalselect, setPrincipalselect] = useState("");
  const [status, setStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sampleIdFilter, setSampleIdFilter] = useState("");
  const [principalFilter, setPrincipalFilter] = useState("");
  const [distributerFilter, setDistributorFilter] = useState("");
  const [recentModified, setRecentModified] = useState(false);
  const [country, setCountry] = useState("");
  const statusList = [
    "PENDING",
    "REJECTED",
    "ACCEPTED",
    "PRODUCTION",
    "SHIPPED",
    "DELIVERED",
  ];
  useEffect(() => {
    console.log("test order", orderStatus, status, startDate, endDate);
  }, [
    orderStatus,
    sampleIdFilter,
    principalFilter,
    distributerFilter,
    status,
    startDate,
    endDate,
  ]);

  //Add product Form Data
  const [updateId, setUpdateId] = useState();
  const [principal, setPrincipal] = useState("");
  const [incoTerms, setIncoTerms] = useState("EXW");
  const [freight, setFreight] = useState("Yes");
  const [insurance, setInsurance] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [invoice, setInvoice] = useState("Yes");
  const [description, setDescription] = useState();
  const [files, setFiles] = useState();

  var jwtToken = localStorage.getItem("token");
  const jwtDecode = jwtToken ? jwt_decode(jwtToken) : null;
  const role = jwtDecode ? jwtDecode.role[0].name : null;
  const id = jwtDecode ? jwtDecode.Id : null;
  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //-------------Get principalList API------------
  // function getprincipalList() {
  //   console.log("categoriesGet");
  //   UserServices.searchPrinciple().then((respons) => {
  //     console.log("categoriesGet", respons.data.data);
  //     setPrincipalList(respons.data.data);
  //     //setPrincipalselect(respons.data.data[0].id);
  //   });
  // }

  const [assignedPrincipalList, setAssignedPrincipalList] = useState([]);

  //-------------Get principalList API------------
  function getAssignedPrincipalById() {
    DistributorServices.getAssignedPrincipalById(id).then((respons) => {
      console.log("respons.data.data.assignedPrinciples", respons.data.data);
      setAssignedPrincipalList(respons.data.data);
      setPrincipalselect(respons?.data?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }

  //-------------Get principalList API------------
  const [principals, setPrincipals] = useState([]);

  function getAllPrincipal() {
    AdminServices.getAllPrincipal().then((respons) => {
      setPrincipals(respons.data);
      setPrincipalselect(respons?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }

  //-------------Get principalList API------------
  const [distributors, setDistributors] = useState([]);

  function getAllDistributor() {
    AdminServices.getAllDistributor().then((respons) => {
      setDistributors(respons.data);
      setPrincipalselect(respons?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }

  //-------------Get Users API------------
  function getGellaryData(e) {
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
    setLoading(true);

    UserServices.countryList(
      page,
      pageSize,
      orderStatus,
      sampleIdFilter,
      principalFilter,
      status,
      startDate,
      endDate,
      recentModified
    ).then((response) => {
      console.log("countryList", response.data);
      const { sample, totalPages, totalElements, data } = response.data;
      setGellaryData(data);
      setTotalItems(totalElements);
      setCount(1);
      setLoading(false);
    });
  }

  useEffect(() => {
    getGellaryData();
    setFileCount(0);
    // getprincipalList();
    getAssignedPrincipalById();
    getAllDistributor();
    getAllPrincipal();
  }, []);

  useEffect(() => {
    getGellaryData();
  }, [
    page,
    pageSize,
    sampleIdFilter,
    principalFilter,
    distributerFilter,
    orderStatus,
    status,
    startDate,
    endDate,
    recentModified,
  ]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: "Country Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return param.row.name;
      },
    },
    {
      field: "region",
      headerName: "Region",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        console.log("param Admin", param);
        function approved(e) {
          swal({
            title: "Are you sure?",
            text: `You want to ${param.row.status ? "Inactive" : "Active"} ${
              param.row.name
            }`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              UserServices.countryStatus(param.row.id, e).then((response) => {
                console.log(response);
                getGellaryData();
                swal("Successfull", {
                  icon: "success",
                });
              });
            }
          });
        }
        return (
          <Switch
            checked={param.value}
            onChange={(e) => approved(e.target.checked)}
          />
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return dayjs(param?.row?.createdAt).format("DD/MM/YYYY");
      },
    },
  ];

  const deleteDistributorOrder = (orderId) => {
    swal({
      title: "Are you sure?",
      text: `You want to Delete #${orderId} Order`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        UserServices.deleteDistributorOrder(orderId)
          .then((response) => {
            console.log("Deleted Order", orderId);
            getGellaryData();
            UserServices.deleteDistributorSampleOrder(orderId)
              .then((response) => {
                console.log("Deleted Order", orderId);
                getGellaryData();
                swal("Successfull", {
                  icon: "success",
                });
              })
              .catch((error) => {
                console.log("error", error);
              });
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    });
  };

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  //-----------------Add Product----------------------------
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [validated, setValidated] = useState(false);
  //-----------------Add Product----------------------------
  const [updateshow, setUpdateshow] = useState(false);

  const updateHandleClose = () => setUpdateshow(false);
  //---------------------Input- multi-File-Function--------------------
  let state = {};
  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");
      //  console.log("fileList", fileList.children[1])

      function updateState(newState) {
        state = { ...state, ...newState };
        console.log(state);
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);
          console.log("filesArr ", filesArr);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
            console.log("formData", state.filesArr, files);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            console.log("state dd", statefilesArr, files);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };
  //----------------------updates-order--------------------------
  const updateHandleShow = () => {
    setUpdateshow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");
      //  console.log("fileList", fileList.children[1])

      function updateState(newState) {
        state = { ...state, ...newState };
        console.log(state);
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
            console.log("formData", state.filesArr, files);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            console.log("state dd", statefilesArr, files);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };

  const editorConfiguration = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "fontfamily",
        "fontsize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "subscript",
        "superscript",
        "code",
        "|",
        "blockQuote",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
      wordcount: {
        showCharCount: true,
      },
    },
  };

  // function postAddProduct() {
  //   setLoad(true);
  //   UserServices.countryAdd({ name: country })
  //     .then((respons) => {
  //       console.log("galleryPost", respons);
  //       setShow(false);
  //       setLoad(false);
  //       getGellaryData();
  //       swal({
  //         title: "Done",
  //         text: "New Country has been Added",
  //         icon: "success",
  //         button: "Ok",
  //       });
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  function postAddCountry() {
    setLoad(true);
    UserServices.countryAdd({ name: country })
      .then((respons) => {
        console.log("galleryPost", respons);
        setShow(false);
        setLoad(false);
        getGellaryData();
        swal({
          title: "Done",
          text: "New Country has been Added",
          icon: "success",
          button: "Ok",
        });
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 500) {
          swal({
            title: "Country Already Exists",
            text: "The selected country already exists in the list.",
            icon: "warning",
            button: "Ok",
          });
        } else {
          // Other errors
          swal({
            title: "Error",
            text: "An error occurred while adding the country",
            icon: "error",
            button: "Ok",
          });
        }
        setLoad(false);
      });
  }

  function postUpdateProduct() {
    console.log("formData", jwtDecode.Id);
    setLoad(true);
    var formData = new FormData();
    //  formData.append("paymentTerms", paymentTerms);
    formData.append("incoTerms", incoTerms);
    formData.append("freight", freight);
    if (files !== undefined) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
        console.log("state dd", files[i]);
      }
    }

    //   formData.append("insurance", insurance);
    formData.append("SpecialRequirement", description);
    formData.append("invoice", invoice);
    formData.append("principalId", principal);
    formData.append("user", jwtDecode.Id);
    console.log("formData", formData);

    const formDataObject = {};

    // Iterate over the formData object and populate the formDataObject
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    // // Now you can stringify formDataObject
    console.log(JSON.stringify(formDataObject));

    UserServices.updateSampleOrder(formData, updateId)
      .then((respons) => {
        console.log("galleryPost", respons);
        setUpdateshow(false);
        setUpdateId(null);
        setLoad(false);
        getGellaryData();
        swal({
          title: "Done",
          text: "Order has been Updated",
          icon: "success",
          button: "Ok",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (!country) {
      toast.warning("No country selected");
    } else {
      postAddCountry();
      getGellaryData();
      setValidated(false);
    }
  };

  return (
    <>
      <div className="row">
        <ToastContainer />
        <div className="col-12 col-lg-12 col-xl-12 d-flex">
          <div className="card w-100 rounded-4">
            <div className="card-body">
              <div className="row d-flex justify-content-between mb-2">
                <div class="col-lg-6">
                  <h3 class="mb-0 dash-heading">Country Settings</h3>
                </div>
                <div class="col-lg-6 text-end">
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={handleShow}
                  >
                    <i class="fa fa-plus"></i> Add New Country
                  </button>
                </div>
              </div>
              <hr />

              {/* <div className="row my-3">
                {count > 0 ? (
                  <DataGrid
                    rows={gellaryData}
                    columns={columns}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    loading={loading}
                  />
                ) : (
                  <Box
                    sx={{
                      height: 600,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p>
                      <b>No Data Available</b>
                    </p>
                  </Box>
                )}
              </div> */}

              <div className="row my-3">
                {count > 0 ? (
                  <DataGrid
                    rows={gellaryData}
                    columns={columns}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    loading={loading}
                  />
                ) : (
                  <Box
                    sx={{
                      height: 600,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <p>
                        <b>No Data Available</b>
                      </p>
                    )}
                  </Box>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* add New Country Modal */}

      <Modal show={show} onHide={handleClose} size={"md"} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add New Country</Modal.Title>
        </Modal.Header>
        {load ? <LinearProgress /> : null}
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Col>
                <Autocomplete
                  id="principalfilter"
                  sx={{ width: "100%" }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => {
                    if (value) {
                      setCountry(value);
                    } else {
                      setCountry("");
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search by Country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "Search by Country", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* /*---------------------------Update----------------------------------*/}
      <Modal
        show={updateshow}
        onHide={updateHandleClose}
        size={"lg"}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Sample Order</Modal.Title>
        </Modal.Header>
        {load ? <LinearProgress /> : null}
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Select
                  value={principal}
                  onChange={(e) => setPrincipal(e.target.value)}
                >
                  {assignedPrincipalList?.map((item) => (
                    <option
                      value={item.id}
                      selected={item.id == principal ? true : false}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              {/* <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Payment Terms</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setPaymentTerms(e.target.value)}
                  value={paymentTerms}
                >
                  <option value="15">15 days</option>
                  <option value="30">30 days</option>
                  <option value="45">45 days</option>
                  <option value="60">60 days</option>
                </Form.Select>
              </Form.Group> */}
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Performa Invoive</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setInvoice(e.target.value)}
                  value={invoice}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Inco Term</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setIncoTerms(e.target.value)}
                  value={incoTerms}
                >
                  <option value="EXW">EXW</option>
                  <option value="DAP">DAP</option>
                  <option value="CIF">CIF</option>
                  <option value="CIP">CIP</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Freight</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setFreight(e.target.value)}
                  value={freight}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              {/* <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Insurance</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setInsurance(e.target.value)}
                  value={insurance}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
              </Form.Group> */}
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    <b>Special Requirment</b>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={editorConfiguration}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      //  console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      //    console.log({ event, editor, data });
                      setDescription(data);
                    }}
                    onBlur={(event, editor) => {
                      //console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log('Focus.', editor);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      multiple
                      //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={updateHandleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={postUpdateProduct}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={alertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={alertClose} severity="error" sx={{ width: "100%" }}>
            Please upload Files
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Country;
