import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";

const options = ["Complaint", "Payment", "Debit/Credit Note"];

const ITEM_HEIGHT = 48;

export default function MoreMenu(prop) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  console.log("principal_code", prop.principalName);
  console.log("principal_code", prop.principalId);
  return (
    <div>
      <button type="button" className="btn btn-primary" onClick={handleClick}>
        <MoreVertIcon sx={{ width: "0.8em" }} />
      </button>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem>
          <Link
            to={`/dashboard/complaint`}
            state={{
              orderID: prop.id,
              principalName: prop.principalName, //passing ID and principal
              modelPopUp: true,
              principalId: prop.principalId,
            }}
          >
            Complaint
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to={`/dashboard/payment`}
            state={{
              orderID: prop.id,
              principalName: prop.principalName,
              principalId: prop.principalId,
              modelPopUp: true,
            }}
          >
            Payment
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to={`/dashboard/dcnote`}
            state={{
              orderID: prop.id,
              principalName: prop.principalName,
              principalId: prop.principalId,
              modelPopUp: true,
            }}
          >
            Debit/Credit Note
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
