import { NavLink, NavNavLink } from "react-router-dom";
import jwt_decode from "jwt-decode";
function SideNav() {
  var jwtToken = localStorage.getItem("token");
  const jwtDecode = jwtToken ? jwt_decode(jwtToken) : null;
  const role = jwtDecode ? jwtDecode.role[0].name : null;
  return (
    <>
      <aside class="sidebar-wrapper" data-simplebar="true">
        <div class="sidebar-header">
          <div class="logo-background">
            <img
              src="/assets/images/logo.png"
              class="logo-icon"
              alt="logo icon"
            />
          </div>

          <div class="toggle-icon ms-auto show-mobile">
            <i class="bi bi-list"></i>
          </div>
        </div>

        <ul class="metismenu" id="menu">
          <li></li>
          <li>
            <NavLink to="/dashboard/app" class="has-arrow">
              <div class="parent-icon">
                <i class="bi bi-house-fill"></i>
              </div>
              <div class="menu-title">Dashboard</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/gallery" class="has-arrow">
              <div class="parent-icon">
                <i class="fa fa-image"></i>
              </div>
              <div class="menu-title">Gallery</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/order" class="has-arrow">
              <div class="parent-icon">
                <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
              </div>
              <div class="menu-title">Orders</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/sample" class="has-arrow">
              <div class="parent-icon">
                <i class="bi bi-basket2-fill"></i>
              </div>
              <div class="menu-title">Sample</div>
            </NavLink>
          </li>
          {role == "ROLE_ADMIN" ? (
            <li>
              <NavLink class="has-arrow" to="/dashboard/user">
                <div class="parent-icon">
                  <i class="fa fa-user" aria-hidden="true"></i>
                </div>
                <div class="menu-title">Users</div>
              </NavLink>
            </li>
          ) : null}

          <li>
            <NavLink class="has-arrow" to="/dashboard/payment">
              <div class="parent-icon">
                <i class="bi bi-cash"></i>
              </div>
              <div class="menu-title">Payments</div>
            </NavLink>
          </li>
          <li>
            <NavLink class="has-arrow" to="/dashboard/complaint">
              <div class="parent-icon">
                <i class="bi bi-cloud-arrow-down-fill"></i>
              </div>
              <div class="menu-title">Complaints</div>
            </NavLink>
          </li>

          <li>
            <NavLink class="has-arrow" to="/dashboard/dcnote">
              <div class="parent-icon">
                <i class="bi bi-credit-card-2-back"></i>
              </div>
              <div class="menu-title">Debit/Credit Notes</div>
            </NavLink>
          </li>
          {/* <li>
                    <a class="has-arrow" href="enquiry.html">
                        <div class="parent-icon"><i class="fa fa-wrench"></i></div>
                        <div class="menu-title">Techinical Enquiry</div>
                    </a>
                </li> */}
          <li>
            <NavLink to="/dashboard/technicalquery" class="has-arrow">
              <div class="parent-icon">
                <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
              </div>
              <div class="menu-title">Technical Enquiry</div>
            </NavLink>
          </li>
          <li>
            <NavLink class="has-arrow" to="/dashboard/salesPipeline">
              <div class="parent-icon">
                <i class="fa fa-files-o"></i>
              </div>
              <div class="menu-title">Sales Pipeline</div>
            </NavLink>
          </li>
          {/* <li>
            <a href="forecast.html">
              <div class="parent-icon">
                <i class="bi bi-arrow-clockwise"></i>
              </div>
              <div class="menu-title">Forecast</div>
            </a>
          </li> */}
          <li>
            <NavLink to="/dashboard/budget">
              <div class="parent-icon">
                <i class="bi bi-currency-dollar"></i>
              </div>
              <div class="menu-title">Budget</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/forecast">
              <div class="parent-icon">
                <i class="bi bi-arrow-clockwise"></i>
              </div>
              <div class="menu-title">Forecast</div>
            </NavLink>
          </li>
          {/* <li>
            <a class="has-arrow" href="#">
              <div class="parent-icon">
                <i class="fa fa-file-text-o"></i>
              </div>
              <div class="menu-title">Report</div>
            </a>
          </li> */}
          {role == "ROLE_ADMIN" ? (
            <li class="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#sidebarSetting"
                aria-expanded="false"
                aria-controls="sidebarSetting"
                class="side-nav-link collapsed"
              >
                <div class="parent-icon">
                  <i class="bi bi-gear-fill"></i>
                </div>
                <div class="menu-title">Setting</div>

                <span class="nct-dropdown">
                  {" "}
                  <i class="fa fa-angle-down" aria-hidden="true"></i>{" "}
                </span>
              </a>
              <div class="collapse" id="sidebarSetting">
                <ul class="side-nav-second-level">
                  <li>
                    <NavLink to="/dashboard/smtp">SMTP</NavLink>
                  </li>
                  <li>
                    <NavLink to="/dashboard/currency">Currency</NavLink>
                  </li>
                  <li>
                    <NavLink to="/dashboard/country">Country</NavLink>
                  </li>
                  <li>
                    <NavLink to="/dashboard/company">Company</NavLink>
                  </li>
                  <li>
                    <NavLink to="/dashboard/application">Application</NavLink>
                  </li>
                  <li>
                    <NavLink to="/dashboard/template">Template</NavLink>
                  </li>
                </ul>
              </div>
            </li>
          ) : null}
        </ul>
      </aside>
    </>
  );
}

export default SideNav;
