import { useEffect, useState } from "react";
import UserServices from "../../services/UserServices";
import ServerSideTable from "../serverSideTable";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../style.css";
import { DataGrid } from "@mui/x-data-grid";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import jwt_decode from "jwt-decode";
import Docread from "./docread";
import OrderStatus from "./orderStatus";
import DistributorServices from "../../services/DistributorServices";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import swal from "sweetalert";
import dayjs from "dayjs";
import AdminServices from "../../services/AdminServices";
import CurrencyServices from "../../services/CurrencyServices";
import UploadExcel from "../uploadExcel";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Budget() {
  const [gellaryData, setGellaryData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [principalList, setPrincipalList] = useState([]);
  const [principalselect, setPrincipalselect] = useState("");
  const [status, setStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [orderIdFilter, setOrderIdFilter] = useState("");
  const [principalFilter, setPrincipalFilter] = useState("");
  const [distributerFilter, setDistributorFilter] = useState("");
  const [recentModified, setRecentModified] = useState(false);
  const statusList = ["PENDING", "REJECTED", "APPROVED"];

  //Add product Form Data

  const [updateId, setUpdateId] = useState();
  const [principal, setPrincipal] = useState("");
  const [orderId, setOrderId] = useState("");
  const [productCode, setProductCode] = useState("");
  const [paymentDate, setPaymentDate] = useState();
  const [currency, setCurrency] = useState("");
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState();
  const [files, setFiles] = useState();
  const [year, setYear] = useState("");
  const [annualBudget, setAnnualBudget] = useState(0);
  const [budgetPrice, setBudgetPrice] = useState(0);
  const [quarter1, setQuarter1] = useState(0);
  const [quarter2, setQuarter2] = useState(0);
  const [quarter3, setQuarter3] = useState(0);
  const [quarter4, setQuarter4] = useState(0);
  const [totalBudget, setTotalBudget] = useState("");

  //get all the years from today onwords
  function getLast10Years() {
    const presentYear = new Date().getFullYear();
    const years = [];

    for (let i = presentYear; i <= presentYear + 10; i++) {
      years.push(i);
    }

    return years;
  }

  // Example usage:
  const futureYears = getLast10Years();
  console.log(futureYears);

  // Example usage:
  const last10Years = getLast10Years();

  var jwtToken = localStorage.getItem("token");
  const jwtDecode = jwtToken ? jwt_decode(jwtToken) : null;
  // console.log("jwtDecode", jwtDecode.role[0].name)
  const role = jwtToken ? jwtDecode.role[0].name : null;
  const id = jwtToken ? jwtDecode.Id : null;
  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //-------------Get principalList API------------
  // function getprincipalList() {
  //   console.log("categoriesGet");
  //   UserServices.searchPrinciple().then((respons) => {
  //     console.log("categoriesGet", respons.data.data);
  //     setPrincipalList(respons.data.data);
  //     //setPrincipalselect(respons.data.data[0].id);
  //   });
  // }

  //---------calculating the anuual budget------
  // useEffect(() => {
  //   debugger;
  //   updateAnnualBudget();
  // }, []);

  // Parsing and adding quarters, considering blank values as 0
  const calculateAnnualBudget = () => {
    debugger;

    // Initialize quarterTotal
    let quarterTotal = 0;

    // Add quarters to quarterTotal
    quarterTotal += parseFloat(quarter1) || 0;
    quarterTotal += parseFloat(quarter2) || 0;
    quarterTotal += parseFloat(quarter3) || 0;
    quarterTotal += parseFloat(quarter4) || 0;

    // Log values of quarters for debugging
    console.log("quarter1:", quarter1);
    console.log("quarter2:", quarter2);
    console.log("quarter3:", quarter3);
    console.log("quarter4:", quarter4);

    // Calculate final budget
    const finalBudget = quarterTotal * (budgetPrice || 0);

    // Log final budget for debugging
    console.log("finalBudget:", finalBudget);

    return finalBudget;
  };

  // Update the annual budget state
  const updateAnnualBudget = () => {
    debugger;
    const newAnnualBudget = calculateAnnualBudget();
    setAnnualBudget(newAnnualBudget);
    console.log("newAnnualBudget:", newAnnualBudget);
  };

  const [assignedPrincipalList, setAssignedPrincipalList] = useState([]);

  //-------------Get principalList API------------
  function getAssignedPrincipalById() {
    DistributorServices.getAssignedPrincipalById(id).then((respons) => {
      setAssignedPrincipalList(respons.data.data);
    });
  }

  //-------------Get principalList API------------
  const [principals, setPrincipals] = useState([]);

  function getAllPrincipal() {
    AdminServices.getAllPrincipal().then((respons) => {
      setPrincipals(respons.data);
      // setPrincipalselect(respons?.data[0]?.id);
    });
  }

  //-------------Get principalList API------------
  const [distributors, setDistributors] = useState([]);

  function getAllDistributor() {
    AdminServices.getAllDistributor().then((respons) => {
      setDistributors(respons.data);
      //  setPrincipalselect(respons?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }

  const [currencies, setCurrencies] = useState([]);

  function getCurrencies() {
    CurrencyServices.getCurrencies()
      .then((response) => {
        setCurrencies(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //-------------Get Users API------------
  function getGellaryData(e) {
    debugger;
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
    setLoading(true);
    if (role == "ROLE_DISTRIBUTER") {
      UserServices.budgetDistributor(
        page,
        pageSize,
        orderIdFilter,
        principalFilter,
        recentModified,
        orderStatus,
        status,
        startDate,
        endDate
      ).then((response) => {
        console.log(response.data);
        const { dcNotes, totalPages, totalElements, data } = response.data.data;
        setGellaryData(dcNotes);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    } else if (role == "ROLE_ADMIN") {
      console.log(
        "technicalEnquiryAdmin 1",
        orderStatus,
        orderIdFilter,
        status,
        startDate,
        endDate
      );
      UserServices.budgetAdmin(
        page,
        pageSize,
        orderIdFilter,
        principalFilter,
        distributerFilter,
        recentModified,
        orderStatus,
        status,
        startDate,
        endDate
      ).then((response) => {
        console.log("budget_send_filter", response.data);
        debugger;
        const { budget, totalPages, totalElements, data } = response.data;
        setGellaryData(budget);
        console.log("budget_result_data", budget);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    } else {
      UserServices.budgetPrinciple(
        page,
        pageSize,
        orderIdFilter,
        distributerFilter,
        recentModified,
        orderStatus,
        status,
        startDate,
        endDate
      ).then((response) => {
        const { budget, totalPages, totalElements, data } = response;
        setGellaryData(data);
        setTotalItems(0);
        setCount(1);
        setLoading(false);
      });
    }
  }
  useEffect(() => {
    getGellaryData();
    setFileCount(0);
    // getprincipalList();
    getAssignedPrincipalById();
    getAllDistributor();
    getAllPrincipal();
    getCurrencies();
    var today = new Date();

    // Extract the components of the date
    var year = today.getFullYear();
    var month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
    var day = today.getDate().toString().padStart(2, "0");

    // Format the date as a string
    var formattedDate = year + "-" + month + "-" + day;
    setPaymentDate(formattedDate);
  }, []);

  //default load - page load
  useEffect(() => {
    getGellaryData();
  }, [
    page,
    pageSize,
    orderStatus,
    orderIdFilter,
    status,
    startDate,
    endDate,
    principalFilter,
    distributerFilter,
    recentModified,
  ]);

  const columns = [
    {
      field: "id",
      headerName: "Budget Id",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "userPrinciple",
      headerName: "Principal",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return param.row.userPrinciple == null
          ? "null"
          : param.row.userPrinciple.name;
      },
    },
    {
      field: "productCode",
      headerName: "Product Code",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 180,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "annualBudget",
      headerName: "Annual Budget",
      width: 110,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "budgetPrices",
      headerName: "Budget Prices",
      width: 110,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "currencySymbol",
      headerName: "Currency",
      width: 80,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "totalBudget",
      headerName: "Total Budget",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },

    {
      headerName: "Status",
      width: 140,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <>
            <OrderStatus
              id={param.row.id}
              data={param?.row?.budgetStatuses}
              load={getGellaryData}
              role={role}
            />
          </>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            {" "}
            <Docread data={param.row} doc={param.row.budgetDocuments} />
            {param.row.status === false ? (
              <>
                <button
                  type="button"
                  className="btn btn-primary  mx-1"
                  onClick={() => {
                    debugger;
                    setPrincipal(param.row.userPrinciple.id);
                    setProductCode(param.row.productCode);
                    setProductName(param.row.productName);
                    setYear(param.row.year);
                    setCurrency(param.row.currencySymbol);
                    setAnnualBudget(param.row.annualBudget);
                    setBudgetPrice(param.row.budgetPrices);
                    setQuarter1(param.row.quarter1);
                    setQuarter2(param.row.quarter2);
                    setQuarter3(param.row.quarter3);
                    setQuarter4(param.row.quarter4);
                    setTotalBudget(param.row.totalBudget);
                    setUpdateId(param.row.id);
                    // getGellaryData(); //get total values from API
                    // getSeletedPrincipalProductList(param.row.userPrinciple.id);
                    updateHandleShow();
                  }}
                >
                  <i className="bi bi-pencil"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deletePayment(param.row.id)}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </>
            ) : null}
            {/* //----------------------Read-Doc-----------------------------// */}
            {/* //----------------------Read-Doc-----------------------------// */}
          </>
        );
      },
    },
  ];
  const columnsPrincipal = [
    {
      field: "id",
      headerName: "Budget Id",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: "File Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "comment",
      headerName: "Comment",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: param?.row?.comment,
            }}
          ></div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return dayjs(param?.row?.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      field: "budgetDocuments",
      headerName: "Download File",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <button
            type="button"
            className="btn btn-primary mx-1"
            onClick={(e) =>
              handleDownloads(
                param?.row?.budgetDocuments[0]?.name,
                param?.row?.budgetDocuments[0]?.fileType,
                param?.row?.budgetDocuments[0]?.documentUrl
              )
            }
          >
            <i className="bi bi-download"></i>
          </button>
        );
      },
    },
  ];
  const handleDownloads = async (name, fileType, documentUrl) => {
    //const fileType = documentUrl.split(".")[4];
    console.log("doc_URL", documentUrl);
    console.log("file_type", fileType);
    console.log("file_name ", name);
    try {
      debugger;
      const response = await fetch(documentUrl);
      const blob = await response.blob();

      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${name}.${fileType}`; // Specify the filename for the downloaded file

      // Appending the link to the document body
      document.body.appendChild(link);

      // Triggering the download
      link.click();

      // Cleanup: remove the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const columnsAdmin = [
    {
      field: "id",
      headerName: "Budget Id",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "productCode",
      headerName: "Product Code",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "annualBudget",
      headerName: "Annual Budget",
      width: 140,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "budgetPrices",
      headerName: "Budget Prices",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "currencySymbol",
      headerName: "Currency",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "totalBudget",
      headerName: "Total Budget",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "distributor",
      headerName: "Distributor",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "userPrinciple",
      headerName: "Principal",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return param.row.userPrinciple == null
          ? "null"
          : param.row.userPrinciple.name;
      },
    },
    {
      headerName: "Status",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <>
            <OrderStatus
              id={param.row.id}
              data={param?.row?.budgetStatuses}
              load={getGellaryData}
              role={role}
            />
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Is Approved?",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        function approved(e) {
          const formData = new FormData();
          formData.append("status", e);
          swal({
            title: "Are you sure?",
            text: `You want to Approve #${param.row.id} Budget`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              UserServices.budgetApproved(param.row.id, formData).then(
                (response) => {
                  getGellaryData();
                  swal("Successfull", {
                    icon: "success",
                  });
                }
              );
            }
          });
        }
        return (
          <Switch
            checked={param.value}
            onChange={(e) => approved(e.target.checked)}
            disabled={param.value}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            {/* //----------------------Read-Doc-----------------------------// */}
            <Docread data={param.row} doc={param.row.budgetDocuments} />
            {/* //----------------------Read-Doc-----------------------------// */}
            {/* <button type="button" className="btn btn-primary mx-1">
              <i className="bi bi-pencil"></i>
            </button>
            <button type="button" className="btn btn-primary ">
              <i className="bi bi-trash"></i>
            </button> */}
          </>
        );
      },
    },
  ];

  const deletePayment = (orderId) => {
    swal({
      title: "Are you sure?",
      text: `You want to Delete #${orderId} Budget`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        UserServices.deleteBudget(orderId)
          .then((response) => {
            console.log("Deleted Payment", orderId);
            getGellaryData();
            swal("Successfull", {
              icon: "success",
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    });
  };

  //-----------------Add Product----------------------------
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setProductName("");
    setShow(false);
  };

  const [validated, setValidated] = useState(false);
  //-----------------Add Product----------------------------
  const [updateshow, setUpdateshow] = useState(false);

  const updateHandleClose = () => {
    setUpdateshow(false);
    setPrincipalselect("");
    // setProductCodeList([]);
    setProductName("");
    setYear("");
    setAnnualBudget(0);
    setTotalBudget("");
    setBudgetPrice(0);
    setQuarter1(0);
    setQuarter2(0);
    setQuarter3(0);
    setQuarter4(0);
  };
  //---------------------Input- multi-File-Function--------------------
  let state = {};
  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");
      //  console.log("fileList", fileList.children[1])

      function updateState(newState) {
        state = { ...state, ...newState };
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };
  //----------------------updates-order--------------------------
  const updateHandleShow = () => {
    debugger;
    setUpdateshow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");
      //  console.log("fileList", fileList.children[1])

      function updateState(newState) {
        state = { ...state, ...newState };
        console.log(state);
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
            console.log("formData", state.filesArr, files);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            console.log("state dd", statefilesArr, files);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };

  const editorConfiguration = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "fontfamily",
        "fontsize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "subscript",
        "superscript",
        "code",
        "|",
        "blockQuote",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
      wordcount: {
        showCharCount: true,
      },
    },
  };

  function postAddPayment() {
    debugger;
    if (
      budgetPrice !== "" &&
      productName !== "" &&
      principal !== "" &&
      year !== "" &&
      totalBudget !== "" &&
      currency !== ""
    ) {
      setLoad(true);

      console.log("AnnualBudget", annualBudget);
      var formData = new FormData();
      formData.append("principalId", principalselect);
      formData.append("productCode", productCode);
      formData.append("productName", productName);

      formData.append("year", year);
      formData.append("annualBudget", annualBudget);
      formData.append("budgetPrices", budgetPrice); //need to make changes here to post the anuual budget
      formData.append("quarter1", quarter1);
      formData.append("quarter2", quarter1);
      formData.append("quarter3", quarter3);
      formData.append("quarter4", quarter4);

      formData.append("currencySymbol", currency);
      formData.append("totalBudget", totalBudget);

      // Append files or null if no files
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
      } else {
        formData.append("files", null);
      }

      // Handle dates if needed
      // formData.append("paymentDate", dayjs(paymentDate).format("YYYY/MM/DD"));

      formData.append("enquiryDetails", description);
      formData.append("user", jwtDecode.Id);
      console.log("formData", files, formData);

      const formDataObject = {};

      // Iterate over the formData object and populate the formDataObject
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });

      // Now you can stringify formDataObject
      console.log(JSON.stringify(formDataObject));
      debugger;

      UserServices.newBudget(formData)
        .then((respons) => {
          debugger;
          console.log("galleryPost", respons);
          setShow(false);
          setLoad(false);
          getGellaryData();
          setPrincipalselect("");
          // setProductCodeList([]);
          // setProductName("");
          setYear("");
          setAnnualBudget(0);
          setTotalBudget("");
          setBudgetPrice(0);
          setQuarter1(0);
          setQuarter2(0);
          setQuarter3(0);
          setQuarter4(0);
          swal({
            title: "Done",
            text: "Budget has been Created",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((e) => {
          console.log("err", e);
        });
    } else {
      setOpen(true);
      setMessage("Please fill required field correctly");
    }
  }
  //------------- update product------------
  function postUpdatePayment() {
    setLoad(true);
    var formData = new FormData();
    formData.append("principalId", principal);
    formData.append("productCode", productCode);
    formData.append("productName", productName);
    formData.append("year", year);
    formData.append("annualBudget", annualBudget);
    formData.append("budgetPrices", budgetPrice.toString());
    formData.append("quarter1", quarter1);
    formData.append("quarter2", quarter2);
    formData.append("quarter3", quarter3);
    formData.append("quarter4", quarter4);

    formData.append("currencySymbol", currency);
    formData.append("totalBudget", totalBudget);

    // Append files or null if no files
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    } else {
      formData.append("files", null);
    }

    // formData.append("paymentDate", dayjs(paymentDate).format("YYYY/MM/DD"));
    formData.append("enquiryDetails", description);
    // formData.append("currency", currency);

    formData.append("user", jwtDecode.Id);
    formData.append("id", updateId);

    UserServices.BudgetUpdate(formData, principal)
      .then((respons) => {
        setUpdateshow(false);
        setUpdateId(null);
        setLoad(false);
        getGellaryData();
        debugger;
        swal({
          title: "Done",
          text: "Budget has been Updated",
          icon: "success",
          button: "Ok",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const handleSubmit = (event) => {
    debugger;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (
      productCode !== "" &&
      productName !== "" &&
      totalBudget !== "" &&
      principal !== "" &&
      currency !== "" &&
      year !== ""
    ) {
      postAddPayment();
      getGellaryData();
    } else {
      setOpen(true);
      setMessage("Please fill required field");
    }

    setValidated(true);
  };

  const handleUpdateSubmit = (event) => {
    debugger;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (
      budgetPrice !== "" &&
      productName !== "" &&
      principal !== "" &&
      year !== "" &&
      totalBudget !== "" &&
      currency !== ""
    ) {
      postUpdatePayment();
      getGellaryData();
    } else {
      setOpen(true);
      setMessage("Please fill required field");
    }

    setValidated(true);
  };

  const [productCodeList, setProductCodeList] = useState([]);
  const [productData, setProductData] = useState([]);

  const getSeletedPrincipalProductList = (principalselect) => {
    debugger;
    UserServices.getSeletedPrincipalProductList(principalselect)
      .then((response) => {
        setProductCodeList(response.data);
        console.log("priduct_code_list :" + response.data);
        setProductName("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSeletedPrincipalProductListEdit = (principal) => {
    debugger;
    UserServices.getSeletedPrincipalProductList(principal)
      .then((response) => {
        setProductCodeList(response.data);
        console.log("product_code_list", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductByProductCode = (productCode) => {
    debugger;
    UserServices.getProductByProductCode(productCode)
      .then((response) => {
        setProductData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //-------
  useEffect(() => {
    setProductName(productData?.name);
  }, [productData]);

  useEffect(() => {
    // Check if a principal is selected before making the API call
    getSeletedPrincipalProductList(principalselect);
  }, [principalselect]);

  // Calculate annual budget onBlur

  useEffect(() => {
    debugger;
    // Check if a principal is selected before making the API call
    // if (principalselect) {
    getSeletedPrincipalProductListEdit(principal);
    // }
  }, [principal]);

  useEffect(() => {
    // Check if a principal is selected before making the API call
    // if (productCode) {
    getProductByProductCode(productCode);
    // }
  }, [productCode]);

  const pageType = "budget";

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 d-flex">
          <div className="card w-100 rounded-4">
            <div className="card-body">
              <div className="row d-flex justify-content-between mb-2">
                <div class="col-lg-6">
                  <h3 class="mb-0 dash-heading">Budget</h3>
                </div>
                <div class="col-lg-6 text-end">
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        value={recentModified}
                        onClick={(e) => setRecentModified(e.target.checked)}
                      />
                    }
                    label="Recently Updated"
                  /> */}

                  {role == "ROLE_DISTRIBUTER" ? (
                    <button
                      type="button"
                      class="btn btn-success"
                      onClick={handleShow}
                    >
                      <i class="fa fa-plus"></i> Add Budget
                    </button>
                  ) : role == "ROLE_ADMIN" ? (
                    <UploadExcel
                      principalFilter={principalFilter}
                      principals={principals}
                      pageType={pageType}
                      principalselect={principalselect}
                    />
                  ) : null}
                </div>
              </div>
              <hr />

              {/* role wise filter rendering */}

              <div className="row my-3">
                <div className="col-lg-12">
                  <div className="row">
                    {role === "ROLE_DISTRIBUTER" ? (
                      <div className="col">
                        <div className="form-group label-figma">
                          <Autocomplete
                            id="principalfilter"
                            sx={{ width: "100%" }}
                            options={assignedPrincipalList}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              if (value) {
                                setPrincipalFilter(value.id);
                              } else {
                                setPrincipalFilter("");
                              }
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {option.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search by Principal"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "Search by Principal",
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    ) : role === "ROLE_CLIENT" ? null : role ===
                      "ROLE_ADMIN" ? (
                      <>
                        <div className="col">
                          <div className="form-group label-figma">
                            <Autocomplete
                              id="principalfilter"
                              sx={{ width: "100%" }}
                              options={principals}
                              autoHighlight
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                if (value) {
                                  setPrincipalFilter(value.id);
                                } else {
                                  setPrincipalFilter("");
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search by Principal"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "Search by Principal",
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group label-figma">
                            <Autocomplete
                              id="distirbutorfilters"
                              sx={{ width: "100%" }}
                              options={distributors}
                              autoHighlight
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                if (value) {
                                  setDistributorFilter(value.id);
                                } else {
                                  setDistributorFilter("");
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search by Distributor"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "Search by Distributor",
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}

                    {/* remaining filters rendering based on filters */}
                    {role === "ROLE_DISTRIBUTER" || role === "ROLE_ADMIN" ? (
                      <>
                        <div className="col">
                          <div className="from-group label-figma">
                            <TextField
                              id="outlined-basic"
                              label="Search by Id"
                              variant="outlined"
                              value={orderIdFilter}
                              onChange={(e) => setOrderIdFilter(e.target.value)}
                              sx={{ width: "100%" }}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group label-figma">
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel id="demo-multiple-name-label">
                                Budget Status
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value={orderStatus}
                                onChange={(e) => setOrderStatus(e.target.value)}
                                input={<OutlinedInput label="Budget Status" />}
                              >
                                <MenuItem key={""} value={""}>
                                  All
                                </MenuItem>

                                {statusList.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group label-figma">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Start Date"
                                disableFuture
                                sx={{ width: "100%" }}
                                onChange={(newValue) => {
                                  if (newValue !== null) {
                                    const parsedDate = new Date(newValue);
                                    const year = parsedDate.getFullYear();
                                    const month = String(
                                      parsedDate.getMonth() + 1
                                    ).padStart(2, "0");
                                    const day = String(
                                      parsedDate.getDate()
                                    ).padStart(2, "0");
                                    const formattedDate = `${year}-${month}-${day}`;
                                    setStartDate(formattedDate);
                                  } else {
                                    setStartDate(null);
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group label-figma">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="End Date"
                                disableFuture
                                sx={{ width: "100%" }}
                                onChange={(newValue) => {
                                  if (newValue !== null) {
                                    const parsedDate = new Date(newValue);
                                    const year = parsedDate.getFullYear();
                                    const month = String(
                                      parsedDate.getMonth() + 1
                                    ).padStart(2, "0");
                                    const day = String(
                                      parsedDate.getDate()
                                    ).padStart(2, "0");
                                    const formattedDate = `${year}-${month}-${day}`;
                                    setEndDate(formattedDate);
                                  } else {
                                    setEndDate(null);
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="row my-3">
                  {count > 0 ? (
                    gellaryData ? (
                      role === "ROLE_ADMIN" || role === "ROLE_DISTRIBUTER" ? (
                        <ServerSideTable
                          pageSize={10}
                          page={0}
                          count={count}
                          functionName={getGellaryData}
                          data={gellaryData}
                          columns={
                            role === "ROLE_ADMIN"
                              ? columnsAdmin
                              : role === "ROLE_DISTRIBUTER"
                              ? columns
                              : columnsPrincipal
                          }
                          loading={loading}
                          idname="id"
                        />
                      ) : (
                        <DataGrid
                          rows={gellaryData}
                          columns={columnsPrincipal}
                          hideFooterPagination
                          hideFooterSelectedRowCount
                          loading={loading}
                        />
                      )
                    ) : (
                      <Box
                        sx={{
                          height: 600,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <p>
                            <b>No Data Available</b>
                          </p>
                        )}
                      </Box>
                    )
                  ) : (
                    <Box
                      sx={{
                        height: 600,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <p>
                          <b>No Data Available</b>
                        </p>
                      )}
                    </Box>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------Add new budget ---------------------- */}
      <Modal show={show} onHide={handleClose} size={"lg"} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add New Budget</Modal.Title>
        </Modal.Header>
        {/* {load ? <LinearProgress /> : null} */}
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                  <span style={{ marginLeft: "5px" }}>*</span>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setPrincipalselect(e.target.value)}
                >
                  <option value="" selected>
                    Select Principal
                  </option>
                  {assignedPrincipalList?.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Code</b>
                  <span style={{ marginLeft: "5px" }}>*</span>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setProductCode(e.target.value)}
                >
                  <option value="" selected>
                    Select Product Code
                  </option>
                  {productCodeList?.map((item) => (
                    <option value={item.productCode}>{item.productCode}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Name</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  disabled
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Year</b>
                  <span style={{ marginLeft: "5px" }}>*</span>
                </Form.Label>
                <Form.Select
                  value={year}
                  required
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option value="" selected disabled>
                    Select Year
                  </option>
                  {last10Years.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Annual Budget</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  disabled
                  value={annualBudget}
                  onChange={(e) => setAnnualBudget(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Budget Price</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={budgetPrice}
                  onChange={(e) => {
                    setBudgetPrice(e.target.value);
                    updateAnnualBudget();
                  }}
                  onKeyUp={updateAnnualBudget}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>
                  <b>Quarter 1</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  min="0"
                  required
                  value={quarter1}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      setQuarter1(value);
                      console.log("Quarter 1 updated:", value);
                      updateAnnualBudget();
                    }
                  }}
                  onKeyUp={updateAnnualBudget}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>
                  <b>Quarter 2</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  min="0"
                  value={quarter2}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      setQuarter2(value);
                      updateAnnualBudget();
                    }
                  }}
                  onKeyUp={updateAnnualBudget}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>
                  <b>Quarter 3</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  min="0"
                  value={quarter3}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      setQuarter3(value);
                      updateAnnualBudget();
                    }
                  }}
                  onKeyUp={updateAnnualBudget}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>
                  <b>Quarter 4</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  min="0"
                  required
                  value={quarter4}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      setQuarter4(value);
                      updateAnnualBudget();
                    }
                  }}
                  onKeyUp={updateAnnualBudget}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Currency</b>
                  <span style={{ marginLeft: "5px" }}>*</span>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <option value="" selected>
                    Select Currency
                  </option>
                  {currencies.map((item) => (
                    <option value={item.symbol}>
                      {" "}
                      {item.symbol} {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Total Budget</b>
                  <span style={{ marginLeft: "5px" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  required
                  value={totalBudget}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (
                      input === "" ||
                      parseFloat(input) > 0 ||
                      !isNaN(input)
                    ) {
                      setTotalBudget(input);
                    }
                  }}
                />
              </Form.Group>
            </Row>
            {/* <Row>
              <Col>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    <b>Description</b>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={editorConfiguration}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      //  console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      //    console.log({ event, editor, data });
                      setDescription(data);
                    }}
                    onBlur={(event, editor) => {
                      //console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log('Focus.', editor);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row> */}
            <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      multiple
                      //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      {/* /*---------------------------Update----------------------------------*/}
      <Modal
        show={updateshow}
        onHide={updateHandleClose}
        size={"lg"}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit New Budget</Modal.Title>
        </Modal.Header>
        {/* {load ? <LinearProgress /> : null} */}
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setPrincipalselect(e.target.value)}
                >
                  <option value="" disabled>
                    Select Principal
                  </option>
                  {assignedPrincipalList?.map((item) => (
                    <option
                      value={item.id}
                      selected={item.id == principal ? true : false}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Code</b>
                </Form.Label>
                <Form.Select
                  required
                  // value={productCode}
                  onChange={(e) => setProductCode(e.target.value)}
                >
                  {productCodeList?.map((item) => (
                    <option
                      value={item.productCode}
                      selected={item.productCode == productCode ? true : false}
                    >
                      {item.productCode}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Name</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  disabled
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Year</b>
                </Form.Label>
                <Form.Select
                  value={year}
                  required
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option disabled>Select Year</option>
                  {last10Years.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Annual Budget</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  disabled
                  value={annualBudget}
                  onChange={(e) => setAnnualBudget(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Budget Price</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={budgetPrice}
                  onChange={(e) => {
                    setBudgetPrice(e.target.value);
                    updateAnnualBudget();
                  }}
                  onKeyUp={updateAnnualBudget}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>
                  <b>Quarter 1</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={quarter1}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      setQuarter1(value);
                      console.log("Quarter 1 updated:", value);
                      updateAnnualBudget();
                    }
                  }}
                  onKeyUp={updateAnnualBudget}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>
                  <b>Quarter 2</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={quarter2}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      setQuarter2(value);
                      console.log("Quarter 1 updated:", value);
                      updateAnnualBudget();
                    }
                  }}
                  onKeyUp={updateAnnualBudget}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>
                  <b>Quarter 3</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={quarter3}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      setQuarter3(value);
                      console.log("Quarter 1 updated:", value);
                      updateAnnualBudget();
                    }
                  }}
                  onKeyUp={updateAnnualBudget}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>
                  <b>Quarter 4</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={quarter4}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      setQuarter4(value);
                      console.log("Quarter 1 updated:", value);
                      updateAnnualBudget();
                    }
                  }}
                  onKeyUp={updateAnnualBudget}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Currency</b>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <option disabled>Select Currency</option>
                  {currencies.map((item) => (
                    <option
                      value={item.symbol}
                      selected={item.symbol == currency ? true : false}
                    >
                      {item.symbol} {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Total Budget</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={totalBudget}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (input === "" || parseFloat(input) > 0) {
                      setTotalBudget(input);
                    }
                  }}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      multiple
                      //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={updateHandleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={alertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={alertClose} severity="error" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Budget;
