import http from "../httpcommon";
import authHeader from "./auth-header";

const getAllPrincipal = () => {
  return http.get(`/admin/clients`);
};
const getAllDistributor = () => {
  return http.get(`/admin/distributor`);
};

const AdminServices = {
  getAllPrincipal,
  getAllDistributor,
};
export default AdminServices;
