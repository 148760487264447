import axios from "axios";

export default axios.create({
  //baseURL: "http://62.171.153.83:8080/NutraConnectApi-Web/",
  baseURL:
    "https://api.nutraceutical.digitalnoticeboard.biz/NutraConnectApi-Web",
  // baseURL: "http://192.168.1.156:9090/",
  // baseURL: "http://localhost:9090/",
  headers: {
    "Content-Type": "application/json",
  },
});
