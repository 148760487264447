import { useEffect, useState } from "react";
import UserServices from "../../services/UserServices";
import ServerSideTable from "../serverSideTable";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import http from "../../httpcommon";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import "../style.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import jwt_decode from "jwt-decode";
import Docread from "./docread";
import OrderStatus from "./orderStatus";

import DistributorServices from "../../services/DistributorServices";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import swal from "sweetalert";
import dayjs from "dayjs";
import AdminServices from "../../services/AdminServices";
import UploadExcel from "../uploadExcel";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SalesPipeline() {
  const [gellaryData, setGellaryData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [principalList, setPrincipalList] = useState([]);
  const [principalselect, setPrincipalselect] = useState("");
  const [status, setStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sampleIdFilter, setSampleIdFilter] = useState("");
  const [saleId, setSaleId] = useState("");
  const [principalFilter, setPrincipalFilter] = useState("");
  const [distributerFilter, setDistributorFilter] = useState("");
  const [recentModified, setRecentModified] = useState(false);
  const statusList = ["PENDING", "REJECTED", "APPROVED"];
  // const [baseUrls, setBaseUrls] = useState();
  useEffect(() => {
    console.log("test order", orderStatus, status, startDate, endDate);
  }, [
    orderStatus,
    saleId,
    distributerFilter,
    principalFilter,
    status,
    startDate,
    endDate,
  ]);

  //Add product Form Data
  const [updateId, setUpdateId] = useState();
  const [principal, setPrincipal] = useState("");
  const [orderID, setOrderID] = useState("");
  const [dcNote, setDcNote] = useState("Debit");

  const [description, setDescription] = useState();
  const [files, setFiles] = useState();

  var jwtToken = localStorage.getItem("token");
  const jwtDecode = jwtToken ? jwt_decode(jwtToken) : null;
  const role = jwtDecode ? jwtDecode.role[0].name : null;
  const id = jwtDecode ? jwtDecode.Id : null;
  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //-------------Get principalList API------------
  // function getprincipalList() {
  //   console.log("categoriesGet");
  //   UserServices.searchPrinciple().then((respons) => {
  //     console.log("categoriesGet", respons.data.data);
  //     setPrincipalList(respons.data.data);
  //     //setPrincipalselect(respons.data.data[0].id);
  //   });
  // }

  const [assignedPrincipalList, setAssignedPrincipalList] = useState([]);

  //-------------Get principalList API------------
  function getAssignedPrincipalById() {
    DistributorServices.getAssignedPrincipalById(id).then((respons) => {
      console.log("respons.data.data.assignedPrinciples", respons.data.data);
      setAssignedPrincipalList(respons.data.data);
      setPrincipalselect(respons?.data?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }
  //--------------------download template--------------------

  const downloadTemplate = (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      // Your existing downloadExcel logic
      downloadExcel();

      setLoading(false); // Set loading to false after successful download
      toast.success("Download complete!", { autoClose: 2000 }); // Show success toast
    } catch (error) {
      setLoading(false); // Set loading to false in case of an error
      console.error("Error during download:", error);
      toast.error("Download failed. Please try again."); // Show error toast
    }
  };
  useEffect(() => {
    // const baseURL = http.defaults.baseURL;
    const baseURL =
      "https://api.nutraceutical.digitalnoticeboard.biz/NutraConnectApi-Web";
    // setBaseUrls(http.defaults.baseURL);
    // console.log("Base URL:", baseURL);
    // You can use baseURL in your component logic
  }, []);
  const editorConfiguration = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "fontfamily",
        "fontsize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "subscript",
        "superscript",
        "code",
        "|",
        "blockQuote",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
      wordcount: {
        showCharCount: true,
      },
    },
  };
  const downloadExcel = async () => {
    debugger;
    const baseURL1 = http.defaults.baseURL;

    const PageType = "sales";
    try {
      // Make an API request to get the template information
      const response = await fetch(`${baseURL1}/admin/downloadtemplates`);

      if (!response.ok) {
        throw new toast.error(
          `Failed to fetch template information: ${response.statusText}`
        );
      }
      // Extract the template information from the API response
      const templateInfo = await response.json();
      console.log("template_info:", templateInfo);

      // Determine the template type based on your data
      const templateType = determineTemplateType(templateInfo);
      console.log("template_type:", templateType);

      const downloadURL =
        templateInfo[templateType]?.templateDocument?.documentUrl; //creating URL
      console.log("download_url", downloadURL);

      if (!downloadURL) {
        toast.error(`Template for ${PageType} not found.`);
        return;
      } else {
        // Make another API request to download the actual file
        const fileResponse = await fetch(downloadURL);
        const blob = await fileResponse.blob();
        // Creating a temporary link element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${templateType}.xlsx`;
        // Appending the link to the document body
        document.body.appendChild(link);
        // Triggering the auto download
        link.click();
        // Cleanup: remove the link element
        document.body.removeChild(link);
        toast.success("File download successful");
      }
    } catch (error) {
      console.error("Error while downloading template:", error);
      toast.error("Error while downloading template");
    }
  };

  // Function to determine the template type based on your data structure
  const determineTemplateType = (templateInfo) => {
    return Object.keys(templateInfo)[0]; // Just an example, adjust as needed
  };

  //-------------Get principalList API------------
  const [principals, setPrincipals] = useState([]);

  function getAllPrincipal() {
    AdminServices.getAllPrincipal().then((respons) => {
      setPrincipals(respons.data);
      setPrincipalselect(respons?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }

  //-------------Get principalList API------------
  const [distributors, setDistributors] = useState([]);

  function getAllDistributor() {
    AdminServices.getAllDistributor().then((respons) => {
      setDistributors(respons.data);
      //setPrincipalselect(respons?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }

  //-------------Get Users API------------
  function getGellaryData(e) {
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
    setLoading(true);
    if (role == "ROLE_DISTRIBUTER") {
      debugger;
      UserServices.salesPipelineDistributor(
        page,
        pageSize,
        saleId,
        principalFilter,
        recentModified,
        orderStatus,
        status,
        startDate,
        endDate
      ).then((response) => {
        console.log("distributor_data", response.data);
        const { technicalQueries, totalPages, totalElements, data } =
          response.data.data;
        console.log(technicalQueries);
        setGellaryData(technicalQueries);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    } else if (role == "ROLE_ADMIN") {
      UserServices.salesPipelineAdmin(
        page,
        pageSize,
        saleId,
        principalFilter,
        distributerFilter,
        recentModified,
        orderStatus,
        status,
        startDate,
        endDate
      ).then((response) => {
        console.log(response.data);
        const { sales, totalPages, totalElements, data } = response.data;
        setGellaryData(sales);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    } else {
      UserServices.salesPipelinePrinciple(
        page,
        pageSize,
        saleId,
        distributerFilter,
        recentModified,
        status,
        startDate,
        endDate
      ).then((response) => {
        console.log("reponse_get_principal_request", response);
        const { sales, totalPages, totalElements, data } = response;
        console.log("reponse_get_principal_data", data);
        setGellaryData(data);
        setTotalItems(totalElements);
        setCount(1);
        setLoading(false);
      });
    }
  }
  useEffect(() => {
    getGellaryData();
    setFileCount(0);
    // getprincipalList();
    getAssignedPrincipalById();
    getAllDistributor();
    getAllPrincipal();
  }, []);
  useEffect(() => {
    getGellaryData();
  }, [
    page,
    pageSize,
    saleId,
    principalFilter,
    distributerFilter,
    orderStatus,
    status,
    startDate,
    endDate,
    recentModified,
  ]);

  const columns = [
    {
      field: "id",
      headerName: "Sale Id",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },

    {
      field: "userPrinciple",
      headerName: "Principal",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return param.row.userPrinciple == null
          ? "null"
          : param.row.userPrinciple.name;
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Update",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return dayjs(param.row.updatedAt).format("DD/MM/YYYY");
      },
    },
    {
      headerName: "Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => (
        <OrderStatus
          id={param.row.id}
          data={param?.row?.salesStatuses}
          load={getGellaryData}
          role={role}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            {" "}
            <Docread data={param.row} doc={param.row.dcNotesDocumentations} />
            {param.row.status === false ? (
              <>
                <button
                  type="button"
                  className="btn btn-primary  mx-1"
                  onClick={() => {
                    setPrincipal(param.row.userPrinciple.id);
                    setDescription(param.row.comment);
                    setUpdateId(param.row.id);
                    updateHandleShow();
                  }}
                >
                  <i className="bi bi-pencil"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deleteDistributorOrder(param.row.id)}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </>
            ) : null}
            {/* //----------------------Read-Doc-----------------------------// */}
            {/* //----------------------Read-Doc-----------------------------// */}
          </>
        );
      },
    },
  ];

  const columnsPrincipal = [
    {
      field: "id",
      headerName: "Sale Id",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    // {
    //   field: "name",
    //   headerName: "File Name",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "left",
    // },
    {
      field: "comment",
      headerName: "Comment",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: param?.row?.comment,
            }}
          ></div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return dayjs(param?.row?.createdAt).format("DD/MM/YYYY");
      },
    },

    {
      field: "salesDocuments",
      headerName: "Download File",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <button
            type="button"
            className="btn btn-primary mx-1"
            onClick={(e) => {
              handleDownloads(
                param?.row?.salesDocumentations[0]?.name,
                param?.row?.salesDocumentations[0]?.fileType,
                param?.row?.salesDocumentations[0]?.documentUrl
              );
            }}
          >
            <i className="bi bi-download"></i>
          </button>
        );
      },
    },
  ];

  //downloading template for  popup
  // const handleDownloads = async (name, fileType, documentUrl) => {
  //   //const fileType = documentUrl.split(".")[4]
  //   debugger;
  //   try {
  //     const response = await fetch(documentUrl);
  //     console.log("response_download", response);
  //     const blob = await response.blob();
  //     // console.error("downloading file:", documentUrl.split("."));
  //     // Creating a temporary link element
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = `${name}.${fileType}`;

  //     // Appending the link to the document body
  //     document.body.appendChild(link);

  //     // Triggering the download
  //     link.click();

  //     // Cleanup: remove the link element
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   }
  // };
  const handleDownloads = async (name, fileType, documentUrl) => {
    console.log("doc_URL", documentUrl);
    console.log("doc_file_type", fileType);
    console.log("doc_file_name ", name);
    try {
      debugger;
      const response = await fetch(documentUrl);
      const blob = await response.blob();

      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${name}.${fileType}`; // Specify the filename for the downloaded file

      // Appending the link to the document body
      document.body.appendChild(link);

      // Triggering the download
      link.click();

      // Cleanup: remove the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const columnsAdmin = [
    {
      field: "id",
      headerName: "Sale Id",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "distributor",
      headerName: "Distributor",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "userPrinciple",
      headerName: "Principal",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return param.row.userPrinciple == null
          ? "null"
          : param.row.userPrinciple.name;
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Update",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return dayjs(param.row.updatedAt).format("DD/MM/YYYY");
      },
    },
    {
      headerName: "Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => (
        <OrderStatus
          id={param.row.id}
          data={param?.row?.salesStatuses}
          load={getGellaryData}
          role={role}
        />
      ),
    },
    {
      field: "status",
      headerName: "Is Approved?",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        function approved(e) {
          const formData = new FormData();
          formData.append("status", e);
          swal({
            title: "Are you sure?",
            text: `You want to Approve #${param.row.id} Sales Pipeline`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              UserServices.salesPipelineApproved(formData, param.row.id).then(
                (response) => {
                  console.log(response);
                  getGellaryData();
                  swal("Successful", {
                    icon: "success",
                  });
                }
              );
            }
          });
        }
        return (
          <Switch
            checked={param.value}
            onChange={(e) => approved(e.target.checked)}
            disabled={param.value}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => (
        <>
          {/* modfy */}
          {/* <Docread data={param?.row} doc={param?.row?.salesDocumentations} /> */}

          <Docread data={param.row} doc={param.row.salesDocumentations} />
        </>
      ),
    },
  ];

  const deleteDistributorOrder = (orderId) => {
    swal({
      title: "Are you sure?",
      text: `You want to Delete #${orderId} Order`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        UserServices.dcNotedelete(orderId)
          .then((response) => {
            console.log("Deleted Order", orderId);
            getGellaryData();
            UserServices.deleteDistributorSampleOrder(orderId)
              .then((response) => {
                console.log("Deleted Order", orderId);
                getGellaryData();
                swal("Successfull", {
                  icon: "success",
                });
              })
              .catch((error) => {
                console.log("error", error);
              });
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    });
  };
  //-----------------Add Product----------------------------
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [validated, setValidated] = useState(false);
  //-----------------Add Product----------------------------
  const [updateshow, setUpdateshow] = useState(false);

  const updateHandleClose = () => setUpdateshow(false);
  //---------------------Input- multi-File-Function--------------------
  let state = {};
  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");
      //  console.log("fileList", fileList.children[1])

      function updateState(newState) {
        state = { ...state, ...newState };
        console.log(state);
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
            console.log("formData", state.filesArr, files);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            console.log("state dd", statefilesArr, files);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };
  //----------------------updates-order--------------------------
  const updateHandleShow = () => {
    setUpdateshow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");
      //  console.log("fileList", fileList.children[1])

      function updateState(newState) {
        state = { ...state, ...newState };
        console.log(state);
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
            console.log("formData", state.filesArr, files);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            console.log("state dd", statefilesArr, files);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };

  function postAddProduct() {
    console.log("formData", jwtDecode.Id);
    if (fileCount == 0) {
      setOpen(true);
    } else {
      setLoad(true);
      var formData = new FormData();
      //formData.append("paymentTerms", paymentTerms);
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
        console.log("state dd", files[i]);
      }

      //   formData.append("insurance", insurance);
      formData.append("comment", description);
      formData.append("principalId", principalselect);
      formData.append("user", jwtDecode.Id);
      console.log("formData", files, formData);

      const formDataObject = {};

      // Iterate over the formData object and populate the formDataObject
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });

      // // Now you can stringify formDataObject
      console.log(formDataObject);

      UserServices.salesPipelineSave(formData)
        .then((respons) => {
          console.log("galleryPost", respons);
          setShow(false);
          setLoad(false);
          setDescription("");
          getGellaryData();
          swal({
            title: "Done",
            text: "New sales Pipeline has been Created",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
  //Updated Sales Pipeline
  function postUpdateProduct() {
    console.log("formData", jwtDecode.Id);

    setLoad(true);
    var formData = new FormData();
    if (files !== undefined) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }

    //   formData.append("insurance", insurance);
    formData.append("comment", description);
    formData.append("user", jwtDecode.Id);
    formData.append("id", updateId);
    console.log("formData", formData);

    const formDataObject = {};
    // Iterate over the formData object and populate the formDataObject
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    // // Now you can stringify formDataObject
    console.log(JSON.stringify(formDataObject));

    UserServices.salesPipelineStatusupdate(formData, principal)
      .then((respons) => {
        console.log("galleryPost", respons);
        setUpdateshow(false);
        setUpdateId(null);
        setLoad(false);
        getGellaryData();
        swal({
          title: "Done",
          text: "Sales Pipeline has been Updated",
          icon: "success",
          button: "Ok",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const handleSubmit = (event) => {
    debugger;
    const form = event.currentTarget;
    console.log("form_sales_data", form);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      postAddProduct();
      getGellaryData();
    }

    setValidated(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 d-flex">
          <div className="card w-100 rounded-4">
            <div className="card-body">
              <div className="row d-flex justify-content-between mb-2">
                <div class="col-lg-6">
                  <h3 class="mb-0 dash-heading">Sales Pipeline</h3>
                </div>
                <div class="col-lg-6 text-end">
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        value={recentModified}
                        onClick={(e) => setRecentModified(e.target.checked)}
                      />
                    }
                    label="Recently Updated"
                  /> */}
                  {role == "ROLE_DISTRIBUTER" ? (
                    <button
                      type="button"
                      class="btn btn-success"
                      onClick={handleShow}
                    >
                      <i class="fa fa-plus"></i> Create New Sales Pipeline
                    </button>
                  ) : role == "ROLE_ADMIN" ? (
                    // <UploadExcel
                    //   principalFilter={principalFilter}
                    //   principals={principals}
                    //   pageType={"sales"}
                    //   principalselect={principalselect}
                    // />
                    <UploadExcel
                      principalFilter={principalFilter}
                      principals={principals}
                      pageType={"sales"}
                      principalselect={principalselect}
                      visiblity={"both"}
                    />
                  ) : null}
                </div>
              </div>
              <hr />
              <div className="row my-3">
                <div className="col-lg-12">
                  <div className="row">
                    {role == "ROLE_DISTRIBUTER" ? (
                      <div className="col">
                        <div className="form-group label-figma">
                          <Autocomplete
                            id="principalfilter"
                            sx={{ width: "100%" }}
                            options={assignedPrincipalList}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              if (value) {
                                setPrincipalFilter(value.id);
                              } else {
                                setPrincipalFilter("");
                              }
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {option.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search by Principal"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "Search by Principal", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    ) : role == "ROLE_CLIENT" ? (
                      ""
                    ) : // (
                    //   <div className="col">
                    //     <div className="form-group label-figma">
                    //       <Autocomplete
                    //         id="principalfilter"
                    //         sx={{ width: "100%" }}
                    //         options={assignedPrincipalList}
                    //         autoHighlight
                    //         getOptionLabel={(option) => option.name}
                    //         onChange={(event, value) => {
                    //           if (value) {
                    //             setDistributorFilter(value.id);
                    //           } else {
                    //             setDistributorFilter("");
                    //           }
                    //         }}
                    //         renderOption={(props, option) => (
                    //           <Box component="li" {...props}>
                    //             {option.name}
                    //           </Box>
                    //         )}
                    //         renderInput={(params) => (
                    //           <TextField
                    //             {...params}
                    //             label="Search by Distributor"
                    //             inputProps={{
                    //               ...params.inputProps,
                    //               autoComplete: "Search by Distributor", // disable autocomplete and autofill
                    //             }}
                    //           />
                    //         )}
                    //       />
                    //     </div>
                    //   </div>
                    // )
                    role == "ROLE_ADMIN" ? (
                      <>
                        <div className="col">
                          <div className="form-group label-figma">
                            <Autocomplete
                              id="principalfilter"
                              sx={{ width: "100%" }}
                              options={principals}
                              autoHighlight
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                if (value) {
                                  setPrincipalFilter(value.id);
                                } else {
                                  setPrincipalFilter("");
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search by Principal"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "Search by Principal", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group label-figma">
                            <Autocomplete
                              id="distirbutorfilters"
                              sx={{ width: "100%" }}
                              options={distributors}
                              autoHighlight
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                if (value) {
                                  setDistributorFilter(value.id);
                                } else {
                                  setDistributorFilter("");
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search by Distributor"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "Search by Distributor", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {role === "ROLE_DISTRIBUTER" || role === "ROLE_ADMIN" ? (
                      <>
                        <div className="col">
                          <div className="from-group label-figma">
                            <TextField
                              id="outlined-basic"
                              label="Search by Id"
                              variant="outlined"
                              value={saleId}
                              onChange={(e) => setSaleId(e.target.value)}
                              sx={{ width: "100%" }}
                            />
                          </div>
                        </div>
                        {/* status dropdwn */}
                        <div className="col">
                          <div className="form-group label-figma">
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel id="demo-multiple-name-label">
                                Sales Status
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value={orderStatus}
                                onChange={(e) => setOrderStatus(e.target.value)}
                                input={
                                  <OutlinedInput label="Pipeline Status" />
                                }
                              >
                                <MenuItem key={""} value={""}></MenuItem>
                                {statusList.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>

                        {/* <div className="col-lg-3">
                      <div className="from-group label-figma">
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel id="demo-multiple-name-label">
                            {" "}
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            input={<OutlinedInput label="Status" />}
                          >
                            <MenuItem key={"Approved"} value={"true"}>
                              Approved
                            </MenuItem>
                            <MenuItem key={"Unapproved"} value={"false"}>
                              Unapproved
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div> */}
                        <div className="col">
                          <div className="form-group label-figma">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Start Date"
                                disableFuture
                                sx={{ width: "100%" }}
                                onChange={(newValue) => {
                                  if (newValue !== null) {
                                    // Parse the string date into a Date object
                                    const parsedDate = new Date(newValue);

                                    // Format the date as yyyy-mm-dd
                                    const year = parsedDate.getFullYear();
                                    const month = String(
                                      parsedDate.getMonth() + 1
                                    ).padStart(2, "0");
                                    const day = String(
                                      parsedDate.getDate()
                                    ).padStart(2, "0");
                                    const formattedDate = `${year}-${month}-${day}`;

                                    // Use the formatted date as needed
                                    console.log(formattedDate);

                                    // Set the formatted date in your state or perform any other actions
                                    setStartDate(formattedDate);
                                  } else {
                                    // Handle the case where the date is null
                                    setStartDate(null);
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group label-figma">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="End Date"
                                disableFuture
                                sx={{ width: "100%" }}
                                onChange={(newValue) => {
                                  if (newValue !== null) {
                                    // Parse the string date into a Date object
                                    const parsedDate = new Date(newValue);

                                    // Format the date as yyyy-mm-dd
                                    const year = parsedDate.getFullYear();
                                    const month = String(
                                      parsedDate.getMonth() + 1
                                    ).padStart(2, "0");
                                    const day = String(
                                      parsedDate.getDate()
                                    ).padStart(2, "0");
                                    const formattedDate = `${year}-${month}-${day}`;

                                    // Use the formatted date as needed
                                    console.log(formattedDate);

                                    // Set the formatted date in your state or perform any other actions
                                    setEndDate(formattedDate);
                                  } else {
                                    // Handle the case where the date is null
                                    setEndDate(null);
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="row my-3">
                  {count > 0 ? (
                    gellaryData ? (
                      role === "ROLE_ADMIN" || role === "ROLE_DISTRIBUTOR" ? (
                        <ServerSideTable
                          pageSize={10}
                          page={0}
                          count={count}
                          functionName={getGellaryData}
                          data={gellaryData}
                          columns={
                            role === "ROLE_ADMIN"
                              ? columnsAdmin
                              : role === "ROLE_DISTRIBUTOR"
                              ? columns
                              : columnsPrincipal
                          }
                          loading={loading}
                           idname="id"
                        />
                      ) : (
                        <DataGrid
                          rows={gellaryData}
                          columns={columnsPrincipal}
                          hideFooterPagination
                          hideFooterSelectedRowCount
                          loading={loading}
                        />
                      )
                    ) : (
                      <Box
                        sx={{
                          height: 600,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <p>
                            <b>No Data Available</b>
                          </p>
                        )}
                      </Box>
                    )
                  ) : (
                    <Box
                      sx={{
                        height: 600,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <p>
                          <b>No Data Available</b>
                        </p>
                      )}
                    </Box>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Modal show={show} onHide={handleClose} size={"lg"} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add New Sales Pipeline</Modal.Title>
        </Modal.Header>
        {load ? <LinearProgress /> : null}
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setPrincipalselect(e.target.value)}
                >
                  <option value="" disabled>
                    Select Principal
                  </option>
                  {assignedPrincipalList?.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row>
              <Col>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    <b>Special Requirment</b>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={editorConfiguration}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      //  console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      //    console.log({ event, editor, data });
                      setDescription(data);
                    }}
                    onBlur={(event, editor) => {
                      //console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log('Focus.', editor);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      multiple
                      //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
              <Col style={{ textAlign: "right" }}>
                {/* <Button
                  className="mt-3 mr-5 file-input__label"
                  style={{ borderRadius: "4px" }}
                  onClick={downloadTemplate}
                >
                  {" "}
                  <i className="fa fa-solid fa-download"></i>
                  &nbsp; Download Template
                </Button> */}
                <Button
                  className="mt-3 mr-5  file-input__label"
                  style={{ borderRadius: "4px" }}
                  variant="contained"
                  onClick={downloadTemplate}
                  disabled={loading} // Disable the button when loading is true
                >
                  {!loading && (
                    <i
                      className="fa fa-download"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  <span style={{ marginRight: "auto", marginLeft: "5px" }}>
                    <span>
                      {loading
                        ? "Downloading Template..."
                        : "Download Template"}
                    </span>
                  </span>
                  {loading && (
                    <CircularProgress
                      size={16}
                      style={{ marginRight: "5px" }}
                    />
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add Sales Pipeline
          </Button>
        </Modal.Footer>
      </Modal>
      {/* /*---------------------------Update----------------------------------*/}
      <Modal
        show={updateshow}
        onHide={updateHandleClose}
        size={"lg"}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Sales Pipeline</Modal.Title>
        </Modal.Header>
        {/* {load ? <LinearProgress /> : null} */}
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Select
                  value={principal}
                  onChange={(e) => setPrincipal(e.target.value)}
                >
                  {assignedPrincipalList?.map((item) => (
                    <option
                      value={item.id}
                      selected={item.id == principal ? true : false}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row>
              <Col>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    <b>Special Requirment</b>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={editorConfiguration}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      //  console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      //    console.log({ event, editor, data });
                      setDescription(data);
                    }}
                    onBlur={(event, editor) => {
                      //console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log('Focus.', editor);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      multiple
                      //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={updateHandleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={postUpdateProduct}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={alertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={alertClose} severity="error" sx={{ width: "100%" }}>
            Please upload Files
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default SalesPipeline;
